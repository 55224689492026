import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import Navbar from "../components/Navbar";
import Home from "../pages/Home";
import Process from "../pages/Process";
import TramiteInfo from "pages/TramiteInfo";
import Footer from "components/Footer";
import Denuncias from "pages/Denuncias";

//prettier-ignore
export default function AppRouter() {
  return (
    <Router>
      <Navbar />

      <div className="app-content container">
        <Switch>
          {/* Rutas de página de inicio */}
          <Route exact path="/" component={Home} />
          <Route exact path="/ciudadano" component={Home} />
          <Route exact path="/denuncias" component={Denuncias} />

          {/* Ruta para cualquier trámite según su ID */}
          <Route exact path="/tramite/:id" component={Process} />

          {/* Ruta para ver el estado de un trámite */}
          <Route exact path="/estado" component={TramiteInfo} />

          {/* Ruta para ver el estado de un trámite por navegación directa por URL, esto por si se ingresa por código QR o link externo */}
          <Route exact path="/estado/:id" component={TramiteInfo} />

          {/* <Route path="/404" component={NotFoundPage} /> */}
          <Route path="*">
            <Redirect to="/404" />
          </Route>
        </Switch>
      </div>

      <Footer />
    </Router>
  );
}
