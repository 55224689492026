import ProcessData from "data/ProcessData";
import React from "react";

export default function PersonalInfo({ title, id, isThirdParty = false }) {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingOne">
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse-info-${id}`}
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <h3 className="text-start mb-0">Trámite personal</h3>
        </button>
      </h2>
      <div
        id={`collapse-info-${id}`}
        className="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#infoAccordion"
      >
        <div className="accordion-body">
          <h3 className="text-center fw-bold mb-4 text-primary">{title}</h3>

          <h5 className="text-start fw-bold text-primary">
            <i className="fas fa-scroll me-3"></i>
            Descripción
          </h5>
          <p className="text-justify mb-5">{ProcessData[id]?.info}</p>

          {ProcessData[id]?.directed_to && (
            <>
              <h5 className="text-start fw-bold text-primary">
                <i className="fas fa-users me-3"></i>
                ¿A quién está dirigido?
              </h5>
              <p className="text-justify mb-5">
                {ProcessData[id]?.directed_to}
              </p>
            </>
          )}

          {ProcessData[id]?.requirements.length > 0 && (
            <>
              <h5 className="text-start fw-bold text-primary">
                <i className="fas fa-check-square me-3"></i>
                ¿Qué necesito para hacer el trámite?
              </h5>
              <span className="fw-bold mb-3 d-block text-primary">
                Requisitos Obligatorios:
              </span>

              <div className="w-100 px-0 px-md-5 mb-5">
                <ol className="list-group list-group-numbered">
                  {ProcessData[id]?.requirements.map((item, index) => (
                    <li key={index} className="list-group-item">
                      {item}
                    </li>
                  ))}
                </ol>
              </div>
            </>
          )}

          {ProcessData[id]?.special_requirements && (
            <>
              <span className="fw-bold mb-2 d-block text-primary">
                Requisitos Especiales:
              </span>
              <p className="text-justify mb-5">
                {ProcessData[id]?.special_requirements}
              </p>
            </>
          )}

          <h5 className="text-start fw-bold text-primary">
            <i className="fas fa-question-circle me-3"></i>
            ¿Cómo hago el trámite?
          </h5>
          <span className="fw-bold mb-3 d-block text-primary">
            Pasos a seguir en línea
          </span>

          <div className="w-100 px-0 px-md-5 mb-5">
            <ol className="list-group list-group-numbered">
              {ProcessData[id]?.process_steps.map((item, index) => (
                <li key={index} className="list-group-item">
                  {item}
                </li>
              ))}
            </ol>
          </div>

          {ProcessData[id]?.cost && (
            <>
              <h5 className="text-start fw-bold text-primary">
                <i className="fas fa-dollar-sign me-3"></i>
                ¿Cuál es el costo del trámite?
              </h5>
              <p className="text-justify mb-5">{ProcessData[id]?.cost}</p>
            </>
          )}

          {ProcessData[id]?.validity && (
            <>
              <h5 className="text-start fw-bold text-primary">
                <i className="fas fa-exclamation-circle me-3"></i>
                ¿Cuál es la vigencia de lo emitido al completar el trámite?
              </h5>
              <p className="text-justify mb-5">{ProcessData[id]?.validity}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
