import { useState } from "react";

// custom hook para manejar información, cambiarla leerla y resetearla
export const useForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const reset = () => {
    setValues(initialState);
  };

  // maneja el cambio de los campos en values según el nombre del campo, si es un checkbox cambia su valor según el valor de "checked"
  const handleInputChange = ({ target }) => {
    if (target.type !== "checkbox") {
      return setValues({
        ...values,
        [target.name]: target.value,
      });
    }
    setValues({ ...values, [target.name]: target.checked });
  };

  return [values, handleInputChange, reset, setValues];
};
