import React from "react";
import { useHistory } from "react-router-dom";

import "../styles/Tramite.css";

export default function Tramite({ id, icon, title }) {
  const history = useHistory();

  // redirige al proceso del trámite
  const handleRedirect = () => {
    history.push(`/tramite/${id}`);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="tramite-container col">
      <div className="rounded h-100 d-flex flex-column align-items-center border p-3 bg-light">
        <h5 className="text-primary text-center flex-grow-1 d-flex flex-column align-items-center justify-content-center">
          <i className={`${icon} fa-2x mb-2`}></i>
          {title}
        </h5>
        <div className="w-100 d-flex align-items-center justify-content-center">
          <button
            type="button"
            className="btn btn-info btn-sm me-3"
            data-bs-toggle="modal"
            data-bs-target={`#more-info-${id}`}
          >
            Más información
          </button>
          <button
            onClick={handleRedirect}
            type="button"
            className="btn btn-success btn-sm"
          >
            Comenzar trámite
          </button>
        </div>
      </div>
    </div>
  );
}
