import React from "react";

export default function VideoInfoModal() {
  return (
    <div
      className="modal fade"
      id="videoInfoModal"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="videoInfoModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="videoInfoModal">
              Solicitud tipo video
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <h4 className="text-center fs-4">Información</h4>

            <p className="text-justify w-75 mx-auto">
              <span className="fw-bold me-2">1.</span>
              El video se lo deberá realizar con el rostro completamente
              visible, sin mascarilla, gafas, lentes o gorra.
            </p>

            <p className="text-justify w-75 mx-auto">
              <span className="fw-bold me-2">2.</span>
              El video tiene dos opciones:
              <br />
              <span className="ms-3">- Ser grabado directamente</span>
              <br />
              <span className="ms-3">
                - Grabarlo con anticipación y luego adjuntarlo.
              </span>
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
