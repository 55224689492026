import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import bg_pick from "../imgs/bg-pic.jpg";
import gob_bg from "../imgs/gob-bg.png";
import text_1 from "../imgs/text-01.png";
import text_2 from "../imgs/text-02.png";

import "../styles/Navbar.css";

export default function Navbar() {
  const location = useLocation();
  const [title, setTitle] = useState("Seleccione un trámite");
  const [showBtn, setShowBtn] = useState(true);

  // se encarga que si la ruta es diferente al home, elimina los botones del header para ir al inicio y ver estado del trámite
  // esto para que el usuario no se salga del proceso de un trámite por error y tenga que cancelar si o sí
  // también establece el título del trámite en la barra de navegación para que el usuario sepa que trámite está completando
  useEffect(() => {
    const include = location.pathname.includes("/tramite/");

    if (include) {
      setShowBtn(false);

      const pathId = location.pathname.split("/")[2];
      const hashTable = window.localStorage.getItem("tramites");
      const tableParse = JSON.parse(hashTable);
      return setTitle(tableParse[pathId]);
    }

    setShowBtn(true);
    setTitle("Seleccione un trámite");
  }, [location]);

  return (
    <nav className="nav-container container-fluid px-0 d-flex flex-column align-items-center">
      <img src={bg_pick} alt="Migración Ecuador" id="bg-img" />

      <div className="brands w-100 py-4 d-none d-lg-block">
        <div className="container d-flex align-items-center justify-content-between px-0">
          <div className="d-flex align-items-center justify-content-center gap-4">
            <img
              src={text_1}
              alt="Ministerio de gobierno"
              width={126}
              height={37}
            />
            <img
              src={text_2}
              alt="Ministerio de gobierno"
              width={114}
              height={42}
            />
          </div>

          <img
            src={gob_bg}
            alt="Ministerio de gobierno"
            width={445}
            height={79}
          />
        </div>
      </div>

      <div className="links w-100 py-2">
        <div className="container d-flex align-items-center justify-content-between flex-wrap py-2 py-lg-1 gap-3">
          <h5 className="mb-0 text-white text-justify">{title}</h5>

          <div className="d-flex align-items-center gap-3 flex-wrap justify-content-center">
            {showBtn && (
              <Link
                to="/"
                className="text-white border text-decoration-none px-3 py-1"
              >
                Inicio
              </Link>
            )}

            {showBtn && (
              <Link
                to="/estado"
                className="text-white border text-decoration-none px-3 py-1"
              >
                Ver estado de trámite
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* <button
          className="d-md-none btn text-white"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#MobileMenu"
          aria-controls="MobileMenu"
        >
          <i className="fas fa-bars fa-lg"></i>
        </button> */}

      {/* <MobileMenu /> */}
    </nav>
  );
}
