import React from "react";

import PersonalInfo from "components/process-components/PersonalInfo";
import ThirdPartyInfo from "./ThirdPartyInfo";
import ProcessData from "data/ProcessData";

export default function InfoModal({ id, title }) {
  const isThirdParty = ProcessData[id]?.is_third_party;

  return (
    <div
      className="modal fade"
      id={`more-info-${id}`}
      tabIndex="-1"
      aria-labelledby="moreInfoLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="moreInfoLabel">
              Más información
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="accordion accordion-flush" id="infoAccordion">
              <PersonalInfo title={title} id={id} isThirdParty={isThirdParty} />
              {isThirdParty && <ThirdPartyInfo title={title} id={id} />}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
