import ProcessData from "data/ProcessData";
import React from "react";

export default function ThirdPartyInfo({ title, id }) {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingOne">
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse-third-info-${id}`}
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <h3 className="text-start mb-0">Trámite para terceros</h3>
        </button>
      </h2>
      <div
        id={`collapse-third-info-${id}`}
        className="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#infoAccordion"
      >
        <div className="accordion-body">
          <h3 className="text-center fw-bold mb-4 text-primary">
            {title} para terceros
          </h3>

          <h5 className="text-start fw-bold text-primary">
            <i className="fas fa-scroll me-3"></i>Descripción
          </h5>
          <p className="text-justify mb-5">{ProcessData[id]?.third_info}</p>

          <h5 className="text-start fw-bold text-primary">
            <i className="fas fa-check-square me-3"></i>
            ¿Qué necesito para hacer el trámite?
          </h5>
          <span className="fw-bold mb-3 d-block text-primary">
            Requisitos Obligatorios:
          </span>

          <div className="w-100 px-0 px-md-5 mb-5">
            <ol className="list-group list-group-numbered">
              {ProcessData[id]?.third_requirements.map((item, index) => (
                <li key={index} className="list-group-item">
                  {item}
                </li>
              ))}
            </ol>
          </div>

          <span className="fw-bold mb-2 d-block text-primary">
            Requisitos Especiales:
          </span>
          <p className="text-justify mb-5">
            {ProcessData[id]?.third_special_requirements}
          </p>

          <h5 className="text-start fw-bold text-primary">
            <i className="fas fa-question-circle me-3"></i>¿Cómo hago el
            trámite?
          </h5>
          <span className="fw-bold mb-3 d-block text-primary">
            Pasos a seguir en línea
          </span>

          <div className="w-100 px-0 px-md-5 mb-5">
            <ol className="list-group list-group-numbered">
              {ProcessData[id]?.third_process_steps.map((item, index) => (
                <li key={index} className="list-group-item">
                  {item}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
