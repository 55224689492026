import React, { useState } from "react";

import { useForm } from "hooks/useForm";
import { DefaultSelect, DefaultTextarea } from "templates/input";
import Loading from "../Loading";
import { POST } from "helpers/request";

const defaultValues = {
  fee_int_tipo: "",
  fee_text_descripcion: "",
};

export default function StepOneTramite7({ setStep, setUserData, userData }) {
  // estado por defecto del formulario, tiene el campo fee_int_tipo con un dato para mostrar esa opción seleccionada en un input de tipo select
  const [values, handleInputChange, reset] = useForm({
    ...defaultValues,
    fee_int_tipo: "1",
  });

  const [errorData, setError] = useState(defaultValues);
  const [loading, setLoading] = useState(false);

  // maneja el submit del formulario, maneja errores y si es exitoso cambia el estado del paso del trámite
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      await POST(values, "ventanilla/feedbacks", setError, reset);

      // info for process component user data
      setUserData({
        ...userData,
        fullname: values.remitente,
      });

      setLoading(false);
      setStep(4);

      window.scroll({ top: 100, behavior: "smooth" });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const { fee_int_tipo, fee_text_descripcion } = values;

  return (
    <form
      onSubmit={handleSubmit}
      className="w-100 p-1 p-md-3 p-xl-5 d-flex flex-column mt-3 position-relative border mb-5 rounded"
      autoComplete="off"
    >
      {loading && <Loading />}

      <div className="list-group mb-3">
        <p className="list-group-item fs-5 bg-secondary text-white mb-0">
          Consultas, sugerencias, felicitaciones o quejas
        </p>

        <div className="list-group-item px-1 px-lg-3">
          <div className="row w-100 mx-0">
            <DefaultSelect
              customClass="col-12"
              label="Tipo de trámite"
              type="text"
              inputName="fee_int_tipo"
              onChange={handleInputChange}
              error={errorData.fee_int_tipo}
              value={fee_int_tipo}
              options={[
                { value: "1", name: "Consultas" },
                { value: "2", name: "Sugerencias" },
                { value: "3", name: "Felicitaciones" },
                { value: "4", name: "Quejas" },
              ]}
            />

            <DefaultTextarea
              customClass="col-12"
              label="Descripción"
              inputName="fee_text_descripcion"
              onChange={handleInputChange}
              value={fee_text_descripcion}
              error={errorData.fee_text_descripcion}
              placeholder="Ingrese una descripción"
              rows="5"
            />
          </div>
        </div>
      </div>

      <div className="d-flex w-100 justify-content-end mt-4">
        <button
          type="button"
          className="btn btn-secondary mt-0"
          data-bs-toggle="modal"
          data-bs-target="#cancelarTramite"
        >
          Cancelar
        </button>
        <button type="submit" className="btn btn-success mt-0 ms-4">
          Siguiente
        </button>
      </div>
    </form>
  );
}
