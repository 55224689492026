import React, { useState } from "react";
import { POST_FILE } from "helpers/request";

import { useForm } from "hooks/useForm";
import {
  DefaultFile,
  DefaultInput,
  DefaultSelect,
  DefaultTextarea,
} from "templates/input";
import Loading from "../Loading";

const defaultValues = {
  rec_varchar_institucion: "",
  rec_varchar_oficio: "",
  rec_varchar_remitente: "",
  ciu_varchar_phone: "",
  ciu_varchar_user: "",
  ser_text_obs_ciudadano: "",
  tip_int_id: "",
  file: "",
};

export default function StepOneTramite6({
  setStep,
  setUserData,
  userData,
  setProcessId,
  setFileName,
}) {
  // estado por defecto del formulario, tiene el campo tip_int_id con un dato para mostrar esa opción seleccionada en un input de tipo select
  const [values, handleInputChange, reset] = useForm({
    ...defaultValues,
    tip_int_id: "1",
  });

  // guarda el archivo subido por el usuario
  const [file, setFile] = useState(null);

  const [errorData, setError] = useState(defaultValues);
  const [loading, setLoading] = useState(false);

  // se encarga del submit del formulario, maneja errores y el cambio de paso si es completado con éxito
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      return setError({ ...errorData, file: "Debe adjuntar un archivo" });
    }

    setError(defaultValues);

    setLoading(true);

    try {
      const res = await POST_FILE(
        { ...values, file },
        "ventanilla/recepcion",
        setError,
        reset
      );

      // info for process component user data
      setUserData({
        ...userData,
        fullname: values.rec_varchar_remitente,
        email: values.ciu_varchar_user,
        code: res.ser_text_codigo,
      });

      // process tramite id for steps request handle
      setProcessId(res.ser_int_id);

      setLoading(false);
      setStep(4);

      window.scroll({ top: 100, behavior: "smooth" });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // maneja el cambio de archvio, valida tipo, y peso y maneja errores de los mismos
  const handleFileChange = ({ target }) => {
    setError(defaultValues);

    const reducer = (acc, val) => acc + val.size;
    const files = Array.from(target.files);
    const allowedFiles = files.every(
      (file) => file.type.substr(0, 11) === "application"
    );
    const filesSize = Number((files.reduce(reducer, 0) / 1048576).toFixed(2));

    if (!allowedFiles) {
      setError({
        ...errorData,
        file: "Archivo no permitido",
      });
      return;
    }

    if (filesSize > 5) {
      setError({
        ...errorData,
        file: "Excede el límite de peso para tu archivo",
      });
      return;
    }

    if (target.files.length > 0) {
      setFileName(target.files[0].name);

      setFile(target.files[0]);
    }

    if (target.files.length === 0) {
      setFile(null);
    }

    // let fileUrl;

    // files.forEach((file) => {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     fileUrl = reader.result;
    //     setFile(fileUrl);
    //   };
    //   reader.readAsDataURL(file);
    // });

    // setFile(fileUrl);
  };

  const {
    rec_varchar_institucion,
    rec_varchar_oficio,
    rec_varchar_remitente,
    tip_int_id,
    ciu_varchar_phone,
    ciu_varchar_user,
    ser_text_obs_ciudadano,
  } = values;

  return (
    <form
      onSubmit={handleSubmit}
      className="w-100 p-1 p-md-3 p-xl-5 d-flex flex-column mt-3 position-relative border mb-5 rounded"
      autoComplete="off"
    >
      {loading && <Loading />}

      <div className="list-group mb-3">
        <p className="list-group-item fs-5 bg-secondary text-white mb-0">
          Solicitud de recepción de documentos
        </p>

        <div className="list-group-item px-1 px-lg-3">
          <div className="row w-100 mx-0">
            <DefaultInput
              customClass="col-12 col-md-6"
              label="Nombre de la institución"
              type="text"
              value={rec_varchar_institucion}
              inputName="rec_varchar_institucion"
              onChange={handleInputChange}
              placeholder="Ingrese el nombre de la institución"
              error={errorData.rec_varchar_institucion}
              min="3"
            />

            <DefaultInput
              customClass="col-12 col-md-6"
              label="N° de oficio o documento"
              type="text"
              value={rec_varchar_oficio}
              inputName="rec_varchar_oficio"
              onChange={handleInputChange}
              placeholder="Ingrese el número de oficio o documento"
              error={errorData.rec_varchar_oficio}
              min="3"
            />

            <DefaultInput
              customClass="col-12 col-md-6"
              label="Remitente"
              type="text"
              value={rec_varchar_remitente}
              inputName="rec_varchar_remitente"
              onChange={handleInputChange}
              placeholder="Ingrese sus nombres y apellidos"
              error={errorData.rec_varchar_remitente}
              min="3"
            />

            <DefaultSelect
              customClass="col-12 col-md-6"
              label="Trámite que desea ingresar"
              type="text"
              inputName="tip_int_id"
              onChange={handleInputChange}
              error={errorData.tip_int_id}
              value={tip_int_id}
              options={[
                {
                  value: "1",
                  name: "Solicitud de Movimientos migratorios solicitados por Autoridad Competente",
                },
                {
                  value: "2",
                  name: "Registro y levantamiento de medidas cautelares",
                },
                { value: "3", name: "Información" },
                { value: "4", name: "Otros" },
              ]}
            />

            <DefaultInput
              customClass="col-12 col-md-6"
              label="Correo electrónico"
              type="email"
              placeholder="Ingrese su correo electrónico"
              value={ciu_varchar_user}
              inputName="ciu_varchar_user"
              onChange={handleInputChange}
              error={errorData.ciu_varchar_user}
              min="3"
            />

            <DefaultInput
              customClass="col-12 col-md-6"
              label="Teléfono"
              type="number"
              placeholder="Ingrese su número de celular o teléfono fijo"
              value={ciu_varchar_phone}
              inputName="ciu_varchar_phone"
              onChange={handleInputChange}
              error={errorData.ciu_varchar_phone}
              min="3"
              required={false}
            />

            <DefaultFile
              customClass="col-12"
              label="Documento ( Máximo 5mb )"
              inputName="file"
              extensions="application/*"
              onChange={handleFileChange}
              error={errorData?.file}
            />

            <DefaultTextarea
              label={"Observaciones"}
              value={ser_text_obs_ciudadano}
              inputName="ser_text_obs_ciudadano"
              onChange={handleInputChange}
              placeholder="Escriba cualquier solicitud u observación sobre su pedido"
              error={errorData.ser_text_obs_ciudadano}
              required={false}
              rows="3"
            />

            <span className="fw-normal rounded py-1 rounded mb-2">
              <i className="fas fa-exclamation-circle me-2 text-warning"></i>
              Recuerde que al completar todos los datos se podrá realizar la
              atención de manera más ágil
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex w-100 justify-content-end mt-4">
        <button
          type="button"
          className="btn btn-secondary mt-0"
          data-bs-toggle="modal"
          data-bs-target="#cancelarTramite"
        >
          Cancelar
        </button>
        <button type="submit" className="btn btn-success mt-0 ms-4">
          Siguiente
        </button>
      </div>
    </form>
  );
}
