import React, { useState } from "react";
import { useForm } from "hooks/useForm";
import { POST_FILE } from "helpers/request";

import SelectInputSearch from "components/select-search/SelectInputSearch";
import LocationInputs from "components/step-two-components/LocationInputs";

import { DefaultInput, DefaultTextarea } from "templates/input";
import Loading from "../Loading";
import Tramite3StepTwoForm from "components/process-components/Tramite-3-step-two-form";
import ProcessData from "data/ProcessData";

const defaultValues = {
  ciu_varchar_nacionalidad: "",
  ciu_varchar_paisresidencia: "",
  ser_text_obs_ciudadano: "",
  ciu_varchar_address: "",
  ciu_varchar_estadocivil: "",
  ciu_varchar_phonefijo: "",
  ciu_varchar_fechaingreso: "",
  ciu_varchar_unidadcontrol: "",
  ciu_varchar_motivoestadia: "",
};

export default function StepTwoForm({
  setStep,
  processId,
  thirdParty,
  tramiteId,
}) {
  const [values, handleInputChange, reset, setValues] = useForm({
    ...defaultValues,
    ciu_varchar_paisresidencia: "66",
    estadoCivil: "1",
  });
  const [errorData, setError] = useState(defaultValues);
  // const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const [locations, setLocations] = useState({
    ciu_varchar_provincia: "",
    ciu_varchar_ciudad: "",
  });

  // maneja el submit del formulario, sus errores y cambia de paso si todo fue correcto
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    setError(defaultValues);

    if (!values.ciu_varchar_nacionalidad) {
      setLoading(false);
      return setError({
        ...errorData,
        ciu_varchar_nacionalidad: "Debe escoger su nacionalidad",
      });
    }

    if (!values.ciu_varchar_paisresidencia) {
      setLoading(false);
      return setError({
        ...errorData,
        ciu_varchar_paisresidencia: "Debe escoger su país de residencia",
      });
    }

    if (values.ciu_varchar_paisresidencia === "66") {
      if (
        locations.ciu_varchar_provincia === "-1" ||
        locations.ciu_varchar_provincia === ""
      ) {
        setLoading(false);
        return setError({
          ...errorData,
          ciu_varchar_provincia: "Debe seleccionar una provincia",
        });
      }
      if (
        locations.ciu_varchar_ciudad === "-1" ||
        locations.ciu_varchar_ciudad === ""
      ) {
        setLoading(false);
        return setError({
          ...errorData,
          ciu_varchar_ciudad: "Debe seleccionar un cantón",
        });
      }
    }

    try {
      await POST_FILE(
        {
          ...values,
          ...locations,
          ser_int_id: processId,
        },
        "ventanilla/stepthreeform",
        setError,
        reset
      );

      setLoading(false);
      setStep(3);

      window.scroll({ top: 100, behavior: "smooth" });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // maneja el cambio del select de nacionalidad
  const handleNationality = (item) => {
    setValues((prevValues) => ({
      ...prevValues,
      ciu_varchar_nacionalidad: item.label,
    }));

    setError({
      ...errorData,
      ciu_varchar_nacionalidad: "",
    });
  };

  // maneja el cambio del select de país
  const handleSelectClick = ({ value }) => {
    setValues((prevValues) => ({
      ...prevValues,
      ciu_varchar_paisresidencia: value,
    }));

    setError({
      ...errorData,
      ciu_varchar_paisresidencia: "",
    });
  };

  // manejo del input de archivo
  const handleFileChange = ({ target }) => {
    if (target.files.length === 0) {
      return;
    }

    setValues({ ...values, file: target.files[0] });
  };

  const { ser_text_obs_ciudadano, ciu_varchar_paisresidencia } = values;

  return (
    <form
      onSubmit={handleSubmit}
      className="w-100 p-1 p-md-3 p-xl-5 d-flex flex-column mt-3 position-relative border mb-5 rounded"
      autoComplete="off"
    >
      {loading && <Loading />}

      {/* Campos adicionales si el trámite es de id 3 */}
      {tramiteId === "3" && (
        <Tramite3StepTwoForm
          handleInputChange={handleInputChange}
          errorData={errorData}
          {...values}
        />
      )}

      {tramiteId !== "1" && (
        <SelectInputSearch
          label="Nacionalidad"
          classes="w-100 mb-3"
          url="locaciones/paises"
          //son los nombres de los campos de el esquema, de id y de nombre
          valueField="pai_int_id"
          nameField="pai_varchar_nombre"
          placeholder="Escoge una opción"
          // defaultValue={ciu_varchar_nacionalidad}
          onChange={(item) => handleNationality(item)}
          error={errorData.ciu_varchar_nacionalidad}
        />
      )}

      <SelectInputSearch
        label={`País de residencia ${thirdParty ? "del titular" : ""}`}
        classes="w-100 mb-3"
        url="locaciones/paises"
        //son los nombres de los campos de el esquema, de id y de nombre
        valueField="pai_int_id"
        nameField="pai_varchar_nombre"
        placeholder="Escoge una opción"
        defaultValue={ciu_varchar_paisresidencia}
        onChange={(item) => handleSelectClick(item)}
        error={errorData.ciu_varchar_paisresidencia}
      />

      {ciu_varchar_paisresidencia === "66" && (
        <LocationInputs
          locations={locations}
          setLocations={setLocations}
          errorData={errorData}
        />
      )}

      <DefaultTextarea
        label={
          tramiteId === "4" || tramiteId === "5"
            ? "Descripción del requerimiento"
            : "Observaciones"
        }
        value={ser_text_obs_ciudadano}
        inputName="ser_text_obs_ciudadano"
        onChange={handleInputChange}
        placeholder="Escriba cualquier solicitud u observación sobre su pedido"
        error={errorData.ser_text_obs_ciudadano}
        required={ProcessData[tramiteId]?.step_two_desc_required}
        rows="3"
      />

      {tramiteId !== "6" && tramiteId !== "7" && (
        <DefaultInput
          label="Documento de identidad"
          type="file"
          onChange={handleFileChange}
        />
      )}

      <div className="d-flex w-100 justify-content-end mt-4">
        <button
          type="button"
          className="btn btn-secondary mt-0"
          data-bs-toggle="modal"
          data-bs-target="#cancelarTramite"
        >
          Cancelar
        </button>
        <button type="submit" className="btn btn-success mt-0 ms-4">
          Siguiente
        </button>
      </div>
    </form>
  );
}
