import React from "react";
import VideoRecorder from "react-video-recorder";

export default function RecordVideo({
  setFile,
  userData,
  fileObj,
  setFileName,
}) {
  const filename = userData.fullname.replace(" ", "-").concat(".webm");

  return (
    <div className="list-group-item px-1 px-lg-3 d-none d-lg-block">
      <label className="fw-bold">Grabar video de presentación</label>

      <p className="text-center mt-3 mb-2">
        <i className="fas fa-exclamation-circle me-2 text-warning"></i>
        Debe tener una cámara conectada o integrada y funcional en su computador
        o laptop
      </p>

      <div className="w-75 mx-auto py-3" style={{ height: 500 }}>
        {/* En su función onRecordingComplete guardará el nombre del video y el archivo en el estado de componente StepThreeForm */}
        <VideoRecorder
          isFlipped={false}
          height={300}
          showReplayControls={true}
          timeLimit={45000}
          replayVideoAutoplayAndLoopOff
          onRecordingComplete={(videoBlob) => {
            let file = new File([videoBlob], "video-usuario.webm", {
              type: videoBlob.type,
            });

            setFileName(filename);

            setFile({
              file,
              upload: false,
              record: true,
            });
          }}
        />
      </div>

      {fileObj.record && (
        <h6 className="falert alert-primary mb-0 mb-3 text-center border rounded p-3">
          Se utilizará el video grabado
        </h6>
      )}

      {fileObj.upload && (
        <h6 className="alert alert-warning mb-0 p-3 mb-3 text-center border rounded mt-3 mb-4">
          Actualmente se está utilizando el video subido para su solicitud,
          puede cambiar de opción y grabar un video clickeando en "Grabar un
          video de presentación en la parte superior"
        </h6>
      )}
    </div>
  );
}
