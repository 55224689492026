import React, { useState } from "react";
import axios from "axios";

import { configMultipart, PDF_DWNLD_GET } from "helpers/request";

import Loading from "../Loading";
import { DefaultCheckbox } from "templates/input";
import FirmaManual from "components/step-three-components/FirmaManual";
import FirmaVideo from "components/step-three-components/FirmaVideo";
import StepThreeInfoModal from "components/process-components/StepThreeInfoModal";

const BASE_URL = process.env.REACT_APP_BASE_BASE_URL;

const defaultValues = {
  axc_varchar_firma: "",
  file: "",
};

export default function StepThreeForm({
  setStep,
  processId,
  userData,
  setFileName,
  tramiteId,
}) {
  const [errorData, setError] = useState(defaultValues);
  // const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  // maneja el tipo de firma que se selecciona
  const [firma, setFirma] = useState({
    manual: false,
    video: false,
  });

  // maneja el tipo de video que se selecciona
  const [videoType, setVideoType] = useState({
    record: false,
    upload: false,
  });

  // guarda el archivo subido por el usuario y el tipo de archivo
  const [fileObj, setFile] = useState({
    file: "",
    record: false,
    upload: false,
    peso: "",
  });

  // maneja el submit del formulario, sus errores y si es completado con éxito cambia el paso
  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(defaultValues);

    if (!firma.video && !firma.manual) {
      setError({ noSingSelected: "Debe escoger un tipo de firma" });
      window.scroll({ top: 100, behavior: "smooth" });
      return;
    }

    if (!fileObj.file) {
      setError({ noFile: "Debe de adjuntar un archivo" });
      window.scroll({ top: 100, behavior: "smooth" });
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("axc_varchar_firma", firma.manual ? "2" : "3");
    formData.append("ser_int_id", processId);
    formData.append("file", fileObj.file);

    try {
      await axios.post(
        `${BASE_URL}ventanilla/stepfourform`,
        formData,
        configMultipart()
      );

      setLoading(false);
      setStep(4);

      window.scroll({ top: 100, behavior: "smooth" });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // maneja el cambio de las checkbox
  const handleCheckChange = ({ target }) => {
    setError({ ...errorData, noSingSelected: "" });
    setFile({
      file: "",
      record: false,
      upload: false,
      peso: "",
    });

    if (!firma[target.name]) {
      const clone = firma;

      for (const key in clone) {
        if (key !== target.name) {
          clone[key] = false;
        }
      }

      clone[target.name] = true;

      setFirma({ ...clone, [target.name]: target.checked });
    }
  };

  // maneja el cambio de archivo y valida su peso y formato
  const handleFileChange = ({ target }) => {
    setError(defaultValues);

    const reducer = (acc, val) => acc + val.size;
    const files = Array.from(target.files);

    let allowedFormat;

    if (firma.manual) {
      allowedFormat = files.every(
        (file) =>
          file.type.substr(0, 11) === "application" ||
          file.type.substr(0, 5) === "image"
      );
    }

    if (firma.video) {
      allowedFormat = files.every((file) => file.type.substr(0, 5) === "video");
    }

    const filesSize = Number((files.reduce(reducer, 0) / 1048576).toFixed(2));

    if (!allowedFormat) {
      setError({
        ...errorData,
        file: "Archivo no permitido",
      });
      return;
    }

    if (firma.manual && filesSize > 2) {
      setError({
        ...errorData,
        file: "Excede el límite de peso para tu archivo",
      });
      return;
    }

    if (firma.video && filesSize > 20) {
      setError({
        ...errorData,
        file: "Excede el límite de peso para tu archivo",
      });
      return;
    }

    // for download correct fyle in step four
    if (target.files.length > 0) {
      setFileName(target.files[0].name);
      setFile({
        file: target.files[0],
        upload: true,
        record: false,
        peso: (target.files[0].size / 1048576).toFixed(2),
      });
    }

    if (target.files.length === 0) {
      setFile({
        file: "",
        record: false,
        upload: false,
        peso: "",
      });
    }

    // let fileUrl;

    // files.forEach((file) => {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     fileUrl = reader.result;
    //     setFile(fileUrl);
    //   };
    //   reader.readAsDataURL(file);
    // });

    // setFile(fileUrl);
  };

  // maneja la descarga del archivo que debe firmar el usuario
  const handleForceDownload = async () => {
    await PDF_DWNLD_GET(
      `ventanilla/stepthreeform/${processId}`,
      `Solicitud-de-trámite-${userData.code}.pdf`
    );
  };

  const { manual, video } = firma;

  return (
    <form
      onSubmit={handleSubmit}
      className="w-100 p-1 p-md-3 p-xl-5 d-flex flex-column mt-3 position-relative border mb-5 rounded"
      autoComplete="off"
    >
      {loading && <Loading />}

      {/* Modal de información */}
      <StepThreeInfoModal />

      {errorData.noFile && (
        <div className="alert alert-danger text-center fw-bold mb-4">
          {errorData.noFile}
        </div>
      )}

      <div className="list-group mb-5">
        <p className="list-group-item fs-5 bg-secondary text-white mb-0 d-flex justify-content-between align-items-center">
          Tipo de solicitud
          <button
            type="button"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#stepThreeInfoModal"
          >
            <i className="far fa-question-circle me-md-2"></i>
            <span className="d-none d-md-inline">Información</span>
          </button>
        </p>

        <div className="list-group-item px-1 px-lg-3 mt-2 gap-2 d-flex flex-column">
          <DefaultCheckbox
            label="Solicitud de trámite (escaneada)"
            value={manual}
            inputName="manual"
            onChange={handleCheckChange}
          />

          <DefaultCheckbox
            label="Video"
            value={video}
            inputName="video"
            onChange={handleCheckChange}
          />

          <span className="text-danger d-block w-100 fw-bold">
            {errorData.noSingSelected}
          </span>
        </div>
      </div>

      {/* De ser tipo de firma video se muestra este componente que permite descargar el archivo a firmar y subirlo */}
      {firma.manual && (
        <FirmaManual
          handleForceDownload={handleForceDownload}
          handleFileChange={handleFileChange}
          errorData={errorData}
          fileObj={fileObj}
        />
      )}

      {/* De ser firma de tipo video muestra este componente que permite grabar o subir un video */}
      {firma.video && (
        <FirmaVideo
          setFile={setFile}
          fileObj={fileObj}
          setFileName={setFileName}
          userData={userData}
          handleFileChange={handleFileChange}
          errorData={errorData}
          tramiteId={tramiteId}
          videoType={videoType}
          setVideoType={setVideoType}
        />
      )}

      <div className="d-flex w-100 justify-content-end mt-4">
        <button
          type="button"
          className="btn btn-secondary mt-0"
          data-bs-toggle="modal"
          data-bs-target="#cancelarTramite"
        >
          Cancelar
        </button>
        <button type="submit" className="btn btn-success mt-0 ms-4">
          Siguiente
        </button>
      </div>
    </form>
  );
}
