import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProcessData from "data/ProcessData";

import StepsFeedback from "../components/StepForms/StepsFeedback";
import StepOneForm from "../components/StepForms/StepOneForm";
import StepTwoForm from "components/StepForms/StepTwoForm";
import StepThreeForm from "components/StepForms/StepThreeForm";
import StepFourForm from "components/StepForms/StepFourForm";
import CancelProcessModal from "components/process-components/CancelProcessModal";
import StepOneTramite6 from "components/StepForms/StepOneTramite6";
import StepOneTramite7 from "components/StepForms/StepOneTramite7";
import StepOneTramite8 from "components/StepForms/StepOneTramite8";

export default function Process() {
  const { id } = useParams();

  // maneja el paso del proceso en el que se encuentra el usuario
  const [step, setStep] = useState(1);
  // maneja si el trámtie es para tercer o no y mostrar las opciones para que se escoga como hacerlo, esto viene del archivo ProcessData que contiene toda la info de los trámites
  const [thirdParty, setThirdParty] = useState(
    ProcessData[id]?.is_third_party ? null : false
  );
  const [processId, setProcessId] = useState(null);
  // guarda localmente los datos ingresados por el usuario para mostrarlos en pasos siguientes
  const [userData, setUserData] = useState({
    fullname: "",
    email: "",
    cdla: "",
    code: "",
    idType: "",
    phone: "",
  });
  // guarda el nombre del archivo que suba el usuario para mostrarlo en pasos siguientes
  const [fileName, setFileName] = useState("");

  // guarda si el trámite se seleccionó para terceros o no
  const handleClick = ({ target }) => {
    if (target.name === "noThird") setThirdParty(false);
    if (target.name === "third") setThirdParty(true);
  };

  useEffect(() => {
    window.scroll({ top: 100, behavior: "smooth" });
  }, []);

  // el trámite 6 al ser especial maneja su propio paso 1 y paso 4
  if (id === "6") {
    return (
      <div className="container py-5">
        {step === 1 && (
          <StepOneTramite6
            setUserData={setUserData}
            setStep={setStep}
            userData={userData}
            setProcessId={setProcessId}
            setFileName={setFileName}
          />
        )}

        {step === 4 && (
          <StepFourForm
            processId={processId}
            userData={userData}
            fileName={fileName}
            tramiteId={id}
          />
        )}

        <CancelProcessModal />
      </div>
    );
  }

  // el trámite 7 al ser especial maneja su propio paso 1 y paso 4
  if (id === "7") {
    return (
      <div className="container py-5">
        {step === 1 && (
          <StepOneTramite7
            setUserData={setUserData}
            setStep={setStep}
            userData={userData}
          />
        )}

        {step === 4 && (
          <StepFourForm
            processId={processId}
            userData={userData}
            fileName={fileName}
            tramiteId={id}
          />
        )}

        <CancelProcessModal />
      </div>
    );
  }

  // el trámite 8 al ser especial maneja su propio paso 1 y paso 4
  if (id === "8") {
    return (
      <div className="container py-5">
        {step === 1 && (
          <StepOneTramite8
            setUserData={setUserData}
            setStep={setStep}
            userData={userData}
          />
        )}

        {step === 4 && (
          <StepFourForm
            processId={processId}
            userData={userData}
            fileName={fileName}
            tramiteId={id}
          />
        )}

        <CancelProcessModal />
      </div>
    );
  }

  return (
    <div className="container py-5">
      {thirdParty === null && (
        <div className="w-100 mt-5 d-flex flex-column align-items-center justify-content-center py-5">
          <h4 className="w-100 text-center mb-5">Tipo de trámite</h4>
          <div className="d-flex align-items-center justify-content-center gap-5 flex-wrap">
            <button
              onClick={handleClick}
              name="noThird"
              type="button"
              className="option-button btn border bg-light"
            >
              <h3 className="text-primary text-center flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                <i className="fas fa-user mb-2 fa-lg"></i>
                Personal
              </h3>
            </button>
            <button
              onClick={handleClick}
              name="third"
              type="button"
              className="option-button btn border bg-light"
            >
              <h3 className="text-primary text-center flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                <i className="fas fa-users mb-2 fa-lg"></i>
                Para un tercero
              </h3>
            </button>
          </div>
        </div>
      )}

      {/* Muestra en que paso del trámite se encuentra el usuario */}
      {thirdParty !== null && <StepsFeedback step={step} />}

      {userData.code && (
        <h5 className="mb-2 mt-5 d-inline-block fw-bold">
          Número de solicitud:{" "}
          <span className="fw-normal">{userData.code}</span>
        </h5>
      )}

      {step === 1 && thirdParty !== null && (
        <StepOneForm
          setStep={setStep}
          thirdParty={thirdParty}
          setProcessId={setProcessId}
          setUserData={setUserData}
          tramiteId={id}
        />
      )}
      {step === 2 && (
        <StepTwoForm
          setStep={setStep}
          processId={processId}
          thirdParty={thirdParty}
          tramiteId={id}
        />
      )}
      {step === 3 && (
        <StepThreeForm
          setStep={setStep}
          processId={processId}
          userData={userData}
          setFileName={setFileName}
          tramiteId={id}
        />
      )}
      {step === 4 && (
        <StepFourForm
          processId={processId}
          userData={userData}
          fileName={fileName}
          tramiteId={id}
        />
      )}

      {/* Modal que lanza una advertencia si se quiere cancelar el trámite */}
      <CancelProcessModal />
    </div>
  );
}
