export default function InputText({
  className,
  label,
  placeholder,
  id,
  value,
  name,
  onChange,
  isInvalid,
  type = "text",
  required = true,
}) {
  return (
    <div className={className}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <input
        onChange={onChange}
        value={value}
        name={name}
        type={type}
        placeholder={placeholder}
        className={`form-control ${isInvalid ? "is-invalid" : ""}`}
        id={id}
        required={required}
        autoComplete="off"
      />
      {isInvalid && (
        <div id={`${id}Feedback`} className="invalid-feedback">
          {isInvalid}
        </div>
      )}
    </div>
  );
}
