const ProcessData = {
  1: {
    is_third_party: true,

    info: "Trámite orientado a emitir el certificado de movimientos migratorios requerido por personas nacionales y/o extranjeras, con la finalidad de mantener información clara del registro de ingreso o salida del país, el cual permitirá a las personas utilizar dicha información para la realización de trámites de servicio migratorio.",
    third_info:
      "Trámite orientado a solicitar el certificado de movimientos migratorios de una tercera persona, con autorización del titular.",

    directed_to: "",

    requirements: [
      "Documento de identidad cédula y/o pasaporte.",
      "Comprobante de pago bancario original, o de transacción en línea. - Nota aclaratoria: el pago correspondiente a la emisión del certificado de movimientos migratorios debe efectuarse posterior a la validación de datos en la entrevista virtual.",
    ],

    special_requirements: "",

    process_steps: [
      "Acceder a la opción “comenzar el trámite” que se encuentra en la presente página. ",
      "Llenar la información solicitada en el formulario.",
      "El trámite será asignado a un analista de servicios virtuales de la Subsecretaría de Migración, el mismo que se pondrá en contacto por medio de correo electrónico o mensajería móvil de 30 minutos a 2 horas laborables.",
      "Una vez realizada la reunión virtual y validación de datos se debe cancelar el valor de 5,00 USD, en el Banco del Pacífico o sus corresponsales a nivel nacional con el código 4.1 Movimientos migratorios, correspondiente a la especie valorada.",
      "Remitir el comprobante de pago al correo del analista asignado.",
      "El usuario recibirá el certificado de movimientos migratorios y la factura en pdf al correo indicado.",
    ],

    cost: "",

    validity: "",

    next_steps: [
      "Un Analista de Servicios Virtuales, se pondrá en contacto con usted, mediante correo electrónico, mensajería móvil o llamada telefónica, en un lapso de 30 minutos a 2 horas laborables.",
      "Una vez realizada la reunión virtual y validación de datos, se debe cancelar el valor de 5,00 USD, en el Banco del Pacífico o sus corresponsales a nivel nacional con el código 4.1 Movimientos migratorios, correspondiente a la especie valorada.",
      "Remitir el comprobante de pago al correo del analista asignado.",
      "Recibirá el certificado de movimientos migratorios y la factura en pdf al correo indicado.",
    ],

    video_format:
      "Mi nombre es [name] con CÉDULA / PASAPORTE / RUC número [id], con fecha [date] solicito a la Subsecretaría de Migración, se genere la emisión de certificado de movimientos migratorios.",

    third_requirements: [
      "Documento de identidad, cédula de ciudadanía y/o pasaporte.",
      "Comprobante de pago bancario original o de transacción en línea. - Nota aclaratoria: el pago correspondiente a la emisión del certificado de movimientos migratorios debe efectuarse posterior a la validación de datos en la entrevista virtual.",
      "Poder general o especial, realizado en una notaría o Consulado del Ecuador en el exterior con la autorización expresa del titular.",
    ],

    third_special_requirements: "",

    third_process_steps: [
      "Acceder a la opción “comenzar el trámite” que se encuentra en la presente página.Acceder a la opción “comenzar el trámite” que se encuentra en la presente página.",
      "Llenar la información solicitada en el formulario.",
      "El trámite será asignado a un Analista de Servicios Virtuales de la Subsecretaría de Migración, el mismo que se pondrá en contacto por medio de correo electrónico o mensajería móvil de 30 minutos a 2 horas laborables.",
      "Una vez realizada la reunión virtual y validada la información, se debe cancelar el valor de 5,00 USD, en el Banco del Pacífico o sus corresponsales a nivel nacional con el código 4.1 Movimientos migratorios, correspondiente a la especie valorada.",
      "Remitir el comprobante de pago, al correo del analista asignado.",
      "El usuario recibirá el certificado de movimientos migratorios y factura electrónica al correo indicado.",
    ],

    estimatedTime: "1 Día(s) Laborable",

    step_two_desc_required: false,
  },
  2: {
    is_third_party: false,

    info: "Trámite orientado a proporcionar información de medidas cautelares, que se encuentren relacionadas con arraigos, dirigido para las personas extranjeras, e impedimentos de salida del país dirigido a personas nacionales.",

    directed_to: "Personas nacionales y/o extranjeras",

    requirements: ["Cédula de ciudadanía o pasaporte"],

    special_requirements: "No Aplica",

    process_steps: [
      "Acceder a la opción “comenzar el trámite” que se encuentra en la presente página y llenar la información solicitada en el formulario establecido.",
      "En el caso de que el usuario requiera información sobre una prohibición de salida del país, un Analista de Servicios Virtuales se pondrá en contacto mediante correo electrónico o mensajería móvil de 30 minutos a 2 horas laborables.",
      "La información será remitida al correo electrónico del titular.",
    ],

    cost: "",

    validity: "",

    next_steps: [
      "Un analista se pondrá en contacto mediante correo electrónico, mensajería o número telefónico.",
      "Una vez realizada la reunión virtual y validación de datos, se indicará el motivo de la prohibición de salida del país.",
      "OBSERVACIÓN: El trámite para el cual se ingresó la solicitud en la plataforma fue para realizar Consultas de medidas cautelares, sin embargo en el concepto del ticket o solicitud ya enviada indica: Solicitud de asesoría legal.",
    ],

    video_format:
      "Mi nombre es [name] con CÉDULA / PASAPORTE / RUC número [id], con fecha [date] solicito a la Subsecretaría de Migración, se genere la emisión de certificado de Consulta de medidas cautelares (arraigo o impedimento de salida del país).",

    third_requirements: [],

    third_special_requirements: "",

    third_process_steps: [],

    estimatedTime: "30 minutos a 2 horas",

    step_two_desc_required: false,
  },
  3: {
    is_third_party: false,

    info: "Trámite orientado a otorgar prórroga migratoria de 90 días adicionales para la permanencia en el país a ciudadanos extranjeros, este trámite no aplica para aquellos ciudadanos que procedan de países de Sudamérica, ciudadanos rusos por convenio bilateral, y ciudadanos que provengan de países de régimen especial, que requieran visa para ingresar al Ecuador.",

    directed_to:
      "Personas extranjeras que se encuentren en el país, y requieran ampliar su estadía por 90 días adicionales.",

    requirements: ["Pasaporte.", "Comprobante de pago bancario original."],

    special_requirements: "Documento Nacional de Identificación.",

    process_steps: [
      "Acceder a la opción “comenzar el trámite” que se encuentra en la presente página.",
      "Llenar la información solicitada en el Formulario Prórrogas Migratorias Para Permanencia en el País.",
      "Recibir un correo electrónico, con fecha y requisitos para aplicar a la prórroga.",
      "Una vez validada la información y de ser procedente, el Analista de Servicios Virtuales solicitará al usuario el pago correspondiente al servicio, en el Banco del Pacífico o sus corresponsales a nivel nacional, con el código 4.6 Prórrogas, correspondiente a la tercera parte del salario básico unificado vigente en el país.",
      "El usuario recibirá mediante correo electrónico la confirmación de la emisión y el registro de la prórroga migratoria para permanencia en el país.",
    ],

    cost: "USD 133.33",

    validity:
      "La emisión y registro de prórroga migratoria para permanencia del país tiene una vigencia de 90 días.",

    next_steps: [
      "Un Analista de Servicios Virtuales indicará mediante correo electrónico la fecha para cancelar el valor de la prórroga y requisitos para aplicar a la misma.",
      "Cancelar el valor de la tercera parte del salario básico unificado vigente en el país, correspondiente al servicio, en el Banco del Pacífico o sus corresponsales a nivel nacional, con el código 4.6 Prórrogas, entregado previamente.",
      "Remitir un correo electrónico con el comprobante de pago adjunto.",
      "El usuario recibirá mediante correo electrónico la confirmación de la emisión y el registro de la prórroga migratoria para permanencia en el país.",
    ],

    video_format: `Mi nombre es [name] con CÉDULA / PASAPORTE / RUC número [id], con fecha [date].

      Solicito a usted se sirva conceder una prórroga de Permiso de Permanencia Autorizada por el término de 90 días en el territorio nacional, tal como señala el artículo 56 de la Ley Orgánica de Movilidad Humana, de 6 de febrero del 2017, en concordancia con el artículo 132 del Reglamento, de 10 de agosto de 2017.

      I request you to grant an extension of Authorized Stay Permission for 90 days more in Ecuador, such as in the article 56 of Ley Orgánica de Movilidad Humana published on February 6, 2017 and according with the article 132 of the Regulation published on August 10, 2017 establish.
    `,

    third_requirements: [],

    third_special_requirements: "",

    third_process_steps: [],

    estimatedTime: "1 Día(s) Laborable",

    step_two_desc_required: false,
  },
  4: {
    is_third_party: false,

    info: "Trámite orientado a realizar la actualización o rectificación de la información migratoria de ciudadanos nacionales o extranjeros en el Sistema Migratorio Ecuatoriano (SIMIEC). ",

    directed_to:
      "El trámite está dirigido a personas nacionales y extranjeras, que requieran hacer una actualización o rectificación de información migratoria.",

    requirements: ["Pasaporte y/o Documento de identidad."],

    special_requirements:
      "En función del tipo de actualización y / o rectificación de datos que se vaya a realizar, el Analista solicitará la documentación correspondiente a cada caso. - OBSERVACIÓN: El trámite para el cual se ingresó la solicitud en la plataforma fue Actualización y/o rectificación de datos migratorios de personas nacionales y extranjeras, sin embargo en el concepto del ticket o solicitud ya enviada indica: Solicitud de asesoría legal",

    process_steps: [
      "Acceder a la opción “comenzar el trámite” que se encuentra en la presente página.",
      "Llenar la información solicitada en el formulario establecido.",
      "Un Analista de Servicios Virtuales de la Subsecretaría de Migración, se pondrá en contacto con el usuario mediante correo electrónico, mensaje o número telefónico en un lapso de 30 minutos a 2 horas laborables.",
      "En el caso de ser necesario, se solicitará respaldos para realizar la actualización o rectificación en el Sistema Migratorio Ecuatoriano SIMIEC.",
    ],

    cost: "",

    validity: "",

    next_steps: [
      "Un Analista de Servicios Virtuales, se pondrá en contacto con usted mediante correo electrónico o mensajería móvil.",
      "El analista solicitará la documentación de respaldo, para proceder con la actualización que requiera.",
      "Una vez realizada la actualización, será notificado al usuario mediante correo electrónico.",
    ],

    video_format:
      "Mi nombre es [name] con CÉDULA / PASAPORTE / RUC número [id], con fecha [date] solicito a la Subsecretaría de Migración, se genere la emisión de certificado de Actualización y/o rectificación de datos migratorios de personas nacionales y extranjeras.",

    third_requirements: [],

    third_special_requirements: "",

    third_process_steps: [],

    estimatedTime: "1 Día(s) Laborable",

    step_two_desc_required: true,
  },
  5: {
    is_third_party: false,

    info: "Trámite orientado a brindar asesoría en temas migratorios, a personas nacionales y extranjeras.",

    directed_to:
      "Personas nacionales y/o extranjeras que requieren de asesoría migratoria.",

    requirements: ["Pasaporte.", "Cédula de identidad y/o ciudadanía."],

    special_requirements:
      "Copia de Visa, copia de sellos migratorios (arribo/ salida), según corresponda.",

    process_steps: [
      "Acceder a la opción “comenzar el trámite” que se encuentra en la presente página. ",
      "Llenar la información solicitada en el formulario asesoría legal gratuita en temas migratorios a ciudadanos nacionales y extranjeros. ",
      "Recibir correo electrónico mediante el cual el analista jurídico, solventa la consulta migratoria. ",
    ],

    cost: "",

    validity: "",

    next_steps: [
      "Un Analista de Servicios Virtuales, revisará la consulta.",
      "El usuario recibirá la respuesta a su consulta, mediante correo electrónico.",
    ],

    video_format:
      "Mi nombre es [name] con CÉDULA / PASAPORTE / RUC número [id], con fecha [date] solicito a la Subsecretaría de Migración, se genere la emisión de certificado de Asesoría legal gratuita en temas migratorios a ciudadanos nacionales y extranjeros.",

    third_requirements: [],

    third_special_requirements: "",

    third_process_steps: [],

    estimatedTime: "2 Día(s) Laborable",

    step_two_desc_required: true,
  },
  6: {
    is_third_party: false,

    info: "Trámite orientado a la recepción y gestión documental de solicitudes emitidas por la Autoridad Competente, o Instituciones en general.",

    directed_to: "Autoridad Competente.",

    requirements: ["Oficio dirigido a la Subsecretaría de Migración."],

    special_requirements: "",

    process_steps: [
      "Acceder a la opción “comenzar el trámite” que se encuentra en la presente página.",
      "Llenar la información solicitada.",
      "La respuesta será remitida por medios oficiales, en los plazos establecidos.",
    ],

    cost: "",

    validity: "",

    next_steps: [
      "Una vez recibido el Oficio solicitado por la Autoridad Competente o Instituciones en general, se asignará a la Unidad respectiva para el trámite correspondiente.",
      "La información solicitada será remitida al correo electrónico indicado.",
    ],

    video_format: "",

    third_requirements: [],

    third_special_requirements: "",

    third_process_steps: [],

    estimatedTime: "2 Día(s) Laborable",

    step_two_desc_required: false,
  },
  7: {
    is_third_party: false,

    info: "Trámite orientado a recibir consultas, sugerencias, felicitaciones, o quejas por parte del usuario.",

    directed_to: "Persona natural o jurídica.",

    requirements: [],

    special_requirements: "",

    process_steps: [
      "Acceder a la opción “comenzar el trámite” que se encuentra en la presente página.",
      "Llenar la información solicitada.",
    ],

    cost: "",

    validity: "",

    next_steps: [],

    video_format: "",

    third_requirements: [],

    third_special_requirements: "",

    third_process_steps: [],

    estimatedTime: "2 Día(s) Laborable",

    step_two_desc_required: true,
  },
  8: {
    is_third_party: false,

    info: "Trámite orientado a recibir denuncias por parte del usuario.",

    directed_to: "Persona natural o jurídica.",

    requirements: [],

    special_requirements: "",

    process_steps: [
      "Acceder a la opción “comenzar el trámite” que se encuentra en la presente página.",
      "Llenar la información solicitada.",
    ],

    cost: "",

    validity: "",

    next_steps: [],

    video_format: "",

    third_requirements: [],

    third_special_requirements: "",

    third_process_steps: [],

    estimatedTime: "2 Día(s) Laborable",

    step_two_desc_required: true,
  },
};

export default ProcessData;
