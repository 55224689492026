import React, { useEffect, useState } from "react";
import { GET } from "helpers/request";

import { DefaultSelect } from "templates/input";

export default function LocationInputs({ locations, setLocations, errorData }) {
  const [data, setData] = useState({
    provincias: [],
    cantones: [],
  });

  // Funciones que guardan la ubicacion seleccionada y traen la siguiente en base a esta
  const getProvincias = async () => {
    const array = await GET(`locaciones/provincias`);
    setData((prevData) => ({ ...prevData, provincias: array }));
  };

  const handleProvincia = async ({ target }) => {
    setLocations({
      ...locations,
      [target.name]: target.value,
    });

    const array = await GET(`locaciones/cantones/${target.value}`);
    setData({ ...data, cantones: array });
  };

  const handleCanton = async ({ target }) => {
    setLocations({
      ...locations,
      [target.name]: target.value,
    });
  };

  useEffect(() => {
    getProvincias();

    setLocations({
      ciu_varchar_provincia: "",
      ciu_varchar_ciudad: "",
    });
  }, [setLocations]);

  return (
    <div className="row w-100 mx-0">
      <DefaultSelect
        customClass="col-12 col-lg-6 px-0 px-lg-2 ps-lg-0"
        disabled={data.provincias.length > 0 ? false : true}
        label="Provincia"
        type="text"
        value={locations.ciu_varchar_provincia}
        inputName="ciu_varchar_provincia"
        onChange={handleProvincia}
        placeholder="Seleccione una provincia"
        error={errorData.ciu_varchar_provincia}
        options={data.provincias.map((item) => ({
          value: item.pro_int_id,
          name: item.pro_text_name,
        }))}
      />

      <DefaultSelect
        customClass="col-12 col-lg-6 px-0 px-lg-2 pe-lg-0"
        disabled={
          locations.ciu_varchar_provincia && data.cantones.length > 0
            ? false
            : true
        }
        label="Cantón"
        type="text"
        value={locations.ciu_varchar_ciudad}
        inputName="ciu_varchar_ciudad"
        onChange={handleCanton}
        placeholder="Seleccione un cantón"
        error={errorData.ciu_varchar_ciudad}
        options={data.cantones.map((item) => ({
          value: item.can_int_id,
          name: item.can_text_name,
        }))}
      />
    </div>
  );
}
