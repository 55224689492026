import React from "react";
import { useHistory } from "react-router";

export default function CancelProcessModal() {
  const history = useHistory();

  const handleClose = () => {
    history.push("/");
    window.scroll({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      className="modal fade"
      id="cancelarTramite"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="cancelarTramiteLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-danger" id="cancelarTramiteLabel">
              <i className="fas fa-exclamation-triangle me-3"></i>
              Cancelar tramite
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p className="text-center fs-4">
              ¿ Estas seguro que deseas cancelar el proceso ?
            </p>
            <p className="text-center">
              Si lo haces tendrás que empezar de nuevo y los datos serán
              borrados
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Continuar proceso
            </button>

            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              Salir del trámite
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
