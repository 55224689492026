import { useState } from "react";
import useRequest from "hooks/useRequest";

import InputText from "inputs/InputText";
import InputTextarea from "inputs/InputTextarea";
import InputFile from "inputs/InputFile";

const defaultData = {
  den_text_lugar: "",
  den_text_descripcion: "",
  den_text_contacto: "",
  den_text_nombres: "",
  den_text_email: "",
};

export default function StepOneTramite8({ setStep }) {
  const { reqBase, reqBaseFormData } = useRequest();

  const [formLoading, setFormLoading] = useState(false);

  const [data, setData] = useState(defaultData);
  const [error, setError] = useState(defaultData);

  const [files, setFiles] = useState([]);

  const handleChange = ({ target }) => {
    setData({ ...data, [target.name]: target.value });
  };

  const handleFiles = ({ target }) => {
    if (!target.files) return;

    setFiles(target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormLoading(true);

    try {
      const res = await reqBase.post("/ventanilla/denuncias", data);

      if (files) {
        for (let index = 0; index < files.length; index++) {
          const formData = new FormData();
          formData.append("den_int_id", res.data.int);
          formData.append("file", files[index]);

          await reqBaseFormData.post(`ventanilla/files_denuncias`, formData);
        }
      }

      setStep(4);

      window.scroll({ top: 0, behavior: "smooth" });
    } catch (error) {
      console.log(error);
      setError(error.response?.data.messages);
      setFormLoading(false);
    }
  };

  const {
    den_text_lugar,
    den_text_descripcion,
    den_text_contacto,
    den_text_nombres,
    den_text_email,
  } = data;

  return (
    <form
      onSubmit={handleSubmit}
      className="w-100 p-1 p-md-3 p-xl-5 d-flex flex-column mt-3 position-relative border mb-5 rounded"
      autoComplete="off"
    >
      <div className="list-group mb-3">
        <p className="list-group-item fs-5 bg-secondary text-white mb-0">
          Denuncias
        </p>

        <div className="list-group-item px-1 px-lg-3">
          <div className="row w-100 mx-0">
            <InputText
              className="col-12 mb-3"
              label="Servicio de Apoyo Migratorio o Unidad de Control Migratorio donde se recibió la atención"
              id="den_text_lugar"
              value={den_text_lugar}
              name="den_text_lugar"
              isInvalid={error.den_text_lugar}
              onChange={handleChange}
            />

            <InputTextarea
              className="col-12 mb-3"
              label="Descripción de la denuncia"
              id="den_text_descripcion"
              value={den_text_descripcion}
              name="den_text_descripcion"
              isInvalid={error.den_text_descripcion}
              onChange={handleChange}
            />

            <InputText
              className="col-12 mb-3"
              label="Número de contacto"
              id="den_text_contacto"
              value={den_text_contacto}
              name="den_text_contacto"
              isInvalid={error.den_text_contacto}
              onChange={handleChange}
            />

            <InputText
              className="col-12 mb-3"
              label="Nombres de contacto"
              id="den_text_nombres"
              value={den_text_nombres}
              name="den_text_nombres"
              isInvalid={error.den_text_nombres}
              onChange={handleChange}
            />

            <InputText
              className="col-12 mb-3"
              label="Correo electrónico"
              id="den_text_email"
              value={den_text_email}
              name="den_text_email"
              isInvalid={error.den_text_email}
              onChange={handleChange}
            />

            <InputFile
              className="col-12 mb-3"
              label="Archivos adjuntos"
              id="den_text_nombres"
              isInvalid={error.den_text_nombres}
              onChange={handleFiles}
              name="den_text_nombres"
              extensions="image/*"
              required={false}
              multiple={true}
            />
          </div>
        </div>
      </div>

      <div className="d-flex w-100 justify-content-end mt-4">
        <button
          type="button"
          className="btn btn-secondary mt-0"
          data-bs-toggle="modal"
          data-bs-target="#cancelarTramite"
          disabled={formLoading ? true : false}
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="btn btn-success mt-0 ms-4"
          disabled={formLoading ? true : false}
        >
          Siguiente
        </button>
      </div>
    </form>
  );
}
