import React from "react";

import {
  DefaultFile,
  DefaultInput,
  DefaultSelectNoPlaceholder,
} from "templates/input";

export default function ThirdPartyForm({
  values,
  handleInputChange,
  setFile,
  errorData,
  setError,
}) {
  const handleFileChange = ({ target }) => {
    setError({ ...errorData, poder_notariado: "" });

    const reducer = (acc, val) => acc + val.size;
    const files = Array.from(target.files);
    const allowedFiles = files.every(
      (file) =>
        file.type.substr(0, 5) === "image" ||
        file.type.substr(0, 11) === "application"
    );
    const filesSize = Number((files.reduce(reducer, 0) / 1048576).toFixed(2));

    if (!allowedFiles) {
      setError({
        ...errorData,
        poder_notariado: "Archivo no permitido",
      });
      return;
    }

    if (filesSize > 6) {
      setError({
        ...errorData,
        poder_notariado: "Excede el límite de peso para tu archivo",
      });
      return;
    }

    setFile(target.files[0]);

    // let fileUrl;

    // files.forEach((file) => {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     fileUrl = reader.result;
    //     setFile(fileUrl);
    //   };
    //   reader.readAsDataURL(file);
    // });
  };

  const {
    pon_varchar_name,
    pon_varchar_lastname,
    pon_varchar_dni_tipo,
    pon_varchar_dni,
    pon_varchar_correo,
    pon_varchar_celular,
  } = values;

  return (
    <div className="list-group mb-3">
      <p className="list-group-item fs-5 bg-secondary text-white mb-0">
        En calidad de representante legal de:
      </p>
      <div className="list-group-item">
        <div className="w-100 row mx-0">
          <DefaultInput
            customClass="col-12 col-md-6"
            label="Nombres"
            type="text"
            value={pon_varchar_name}
            inputName="pon_varchar_name"
            onChange={handleInputChange}
            placeholder="Ingrese sus nombres"
            error={errorData.pon_varchar_name}
          />

          <DefaultInput
            customClass="col-12 col-md-6"
            label="Apellidos"
            type="text"
            value={pon_varchar_lastname}
            inputName="pon_varchar_lastname"
            onChange={handleInputChange}
            placeholder="Ingrese sus apellidos"
            error={errorData.pon_varchar_lastname}
          />

          <DefaultSelectNoPlaceholder
            customClass="col-12 col-md-6"
            label="Tipo de documento de identificación"
            type="text"
            value={pon_varchar_dni_tipo}
            inputName="pon_varchar_dni_tipo"
            onChange={handleInputChange}
            placeholder="Seleccione una opción"
            error={errorData.pon_varchar_dni_tipo}
            options={[
              { value: "1", name: "Cédula" },
              { value: "2", name: "Pasaporte" },
            ]}
          />

          <DefaultInput
            customClass="col-12 col-md-6"
            label={pon_varchar_dni_tipo === "1" ? "Cédula" : "Pasaporte"}
            type="number"
            value={pon_varchar_dni}
            inputName="pon_varchar_dni"
            onChange={handleInputChange}
            placeholder="Ingrese sus datos"
            error={errorData.pon_varchar_dni}
            min="10"
          />

          <DefaultInput
            customClass="col-12 col-md-6"
            label="Correo electrónico"
            type="email"
            value={pon_varchar_correo}
            inputName="pon_varchar_correo"
            onChange={handleInputChange}
            placeholder="Ingrese su correo"
            error={errorData.pon_varchar_correo}
            required={false}
          />

          <DefaultInput
            customClass="col-12 col-md-6"
            label="Teléfono"
            type="number"
            value={pon_varchar_celular}
            inputName="pon_varchar_celular"
            onChange={handleInputChange}
            placeholder="Ingrese su teléfono"
            error={errorData.pon_varchar_celular}
            required={false}
            min="6"
          />

          <DefaultFile
            customClass="col-12"
            label="Mi Poderdante ( Obligatorio presentar Poder Notariado )"
            inputName="file"
            extensions="application/pdf, image/*"
            onChange={handleFileChange}
            error={errorData.poder_notariado}
          />

          <div className="accordion mb-2" id="requisitos_input_file">
            <div className="accordion-item">
              <h2 className="accordion-header" id="titulo">
                <button
                  className="accordion-button p-2 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#requisitos"
                  aria-expanded="false"
                  aria-controls="requisitos"
                >
                  Requerimientos para subir archivo
                </button>
              </h2>
              <div
                id="requisitos"
                className="accordion-collapse collapse show"
                aria-labelledby="titulo"
                data-bs-parent="#requisitos_input_file"
                aria-expanded="false"
              >
                <div className="accordion-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">Máximo 1 fichero</li>
                    <li className="list-group-item">Límite de 6 MB</li>
                    <li className="list-group-item">
                      Tipos permitidos:{" "}
                      <span className="text-danger">pdf, jpg, png, jpeg.</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
