import React, { useEffect, useState } from "react";
import { GET } from "helpers/request";

import InfoModal from "components/process-components/InfoModal";
import Tramite from "components/Tramite";

export default function Home() {
  const [data, setData] = useState([]);

  // obtiene todos los trámites y los lista, también los guarda en el local storage para usarlos para obtenter sus títulos y mostrarlos en el navbar
  // esto en el componente navbar
  const getData = async () => {
    const tramites = await GET("ventanilla/serv_tipos?l=999");
    setData([
      ...tramites.datos,
      {
        tip_int_id: "8",
        tip_varchar_name: "Denuncias",
        tip_text_description: null,
        tip_varchar_url: null,
        tip_varchar_filename: "far fa-comment",
        total: "7",
      },
    ]);

    const reducer = (acc, val) => ({
      ...acc,
      [val.tip_int_id]: val.tip_varchar_name,
    });
    const hashTable = tramites.datos.reduce(reducer, {});
    window.localStorage.setItem("tramites", JSON.stringify(hashTable));
  };

  // llama a getData la primera vez que se monta el componente
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container py-5">
      <div className="container-fluid">
        <div className="row g-4 row-cols-auto justify-content-center">
          {data.length === 0 && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: 700 }}
            >
              <div
                className="spinner-border text-primary"
                style={{ width: "4rem", height: "4rem" }}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {/* Componente que renderiza los trámites */}
          {data.length > 0 &&
            data.map((item) => (
              <Tramite
                key={item.tip_int_id}
                id={item.tip_int_id}
                icon={item.tip_varchar_filename}
                title={item.tip_varchar_name}
                desc={item.tip_text_description}
              />
            ))}

          {/* Modales de información de cada trámite */}
          {data.length > 0 &&
            data.map((item) => (
              <InfoModal
                key={item.tip_int_id}
                id={item.tip_int_id}
                title={item.tip_varchar_name}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
