import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";

import { GET } from "helpers/request";

export default function SelectInputSearch({
  url,
  valueField,
  nameField,
  lastNameField,
  classes,
  placeholder,
  onChange,
  label,
  defaultValue,
  error,
}) {
  const [inputValue, setInputValue] = useState({});
  const [data, setData] = useState([{ value: null, label: "Cargando..." }]);
  const [loading, setLoading] = useState(true);

  // función que trae los datos según la url pasada por props, mapea y lista correctamente los datos según la librería, maneja sus errores internamente
  // si por props recibe el nombre de un campo lastNameField, significa que hay nombre y apellido en la respuesta y en base a eso mapeará los datos de manera especial
  const getData = useCallback(async () => {
    const res = await GET(url);
    let mapped;

    if (res?.length === 0) {
      mapped = [
        {
          value: "0",
          label: "No hubo resultados",
        },
      ];

      setLoading(false);
      return setData(mapped);
    }

    if (res?.length >= 1) {
      if (lastNameField) {
        mapped = res.map((item) => ({
          value: item[valueField],
          label: item[nameField] + " " + item[lastNameField],
        }));

        setInputValue(mapped.filter((item) => item.value === defaultValue));
        setLoading(false);
        return setData(mapped);
      }

      mapped = res.map((item) => ({
        value: item[valueField],
        label: item[nameField],
      }));

      setInputValue(mapped.filter((item) => item.value === defaultValue));
      setLoading(false);
      return setData(mapped);
    }
  }, [url, nameField, valueField, lastNameField, defaultValue]);

  // función que maneja el cambio o seleccion de una opción
  const handleChange = (value) => {
    onChange(value);
    setInputValue(value);
  };

  // llama los datos la primera vez que se monta el componente
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className={`${classes} ${error && "form-control is-invalid"}`}>
      <label className="fw-bold mb-2">{label}</label>
      {/* Componente de la librería de select con búsqueda */}
      <Select
        options={data}
        placeholder={placeholder}
        isLoading={loading}
        onChange={(value) => handleChange(value)}
        value={inputValue}
      />
      {error && <p className="mt-2 text-danger w-100 mb-0">{error}</p>}
    </div>
  );
}
