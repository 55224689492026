import React, { useRef } from "react";

import ProcessData from "data/ProcessData";

import { DefaultCheckbox, DefaultFile } from "templates/input";
import RecordVideo from "./RecordVideo";
import getDateString from "helpers/dateString";

import VideoInfoModal from "components/process-components/VideoInfoModal";

export default function FirmaManual({
  setFile,
  fileObj,
  setFileName,
  userData,
  handleFileChange,
  errorData,
  tramiteId,
  videoType,
  setVideoType,
}) {
  const videoInfoRef = useRef();

  const dateNow = getDateString(new Date());

  // Obtiene el texto que el usuario debe leer para grabar su video de la data el mismo en ProcessData, y reemplaza las palabras de ejemplo por los datos del usuario ingresados en el paso 1
  const videoFormatString = () => {
    // let str = JSON.stringify(ProcessData[tramiteId].video_format);
    let str = ProcessData[tramiteId].video_format;

    str = str.replace("[name]", userData.fullname);
    str = str.replace("[id]", userData.cdla);
    str = str.replace("[date]", dateNow);

    return str;
  };

  return (
    <>
      <VideoInfoModal />

      <div className="list-group mb-5">
        <p className="list-group-item fs-5 bg-secondary text-white mb-0">
          Formato de video ( Leer texto )
        </p>

        <div
          className="list-group-item px-1 px-lg-3 mt-2"
          style={{ whiteSpace: "pre-line" }}
        >
          {videoFormatString()}
        </div>
      </div>

      <div className="list-group mb-3">
        <p className="list-group-item fs-5 bg-secondary text-white mb-0 d-flex justify-content-between align-items-center">
          Adjunte su archivo
          <button
            type="button"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#videoInfoModal"
            ref={videoInfoRef}
          >
            <i className="far fa-question-circle me-md-2"></i>
            <span className="d-none d-md-inline">Información</span>
          </button>
        </p>

        <div className="list-group-item px-1 px-lg-3 mt-2 gap-2 d-flex flex-column">
          <DefaultCheckbox
            label="Grabar video de presentación"
            value={videoType.record}
            inputName="record"
            onChange={() => {
              setVideoType({ record: true, upload: false });
              videoInfoRef.current.click();
            }}
          />

          <DefaultCheckbox
            label="Subir video de presentación"
            value={videoType.upload}
            inputName="upload"
            onChange={() => setVideoType({ upload: true, record: false })}
          />

          <span className="text-danger d-block w-100 fw-bold">
            {errorData.noSingSelected}
          </span>
        </div>

        {/* De querer grabar un video se mostrará este componente, una librería para grabar video con la cámara del pc */}
        {videoType.record && (
          <RecordVideo
            setFile={setFile}
            userData={userData}
            fileObj={fileObj}
            setFileName={setFileName}
          />
        )}

        {videoType.upload && (
          <div className="list-group-item px-1 px-lg-3">
            <DefaultFile
              customClass="col-12"
              label="Subir video de presentación"
              inputName="file"
              onChange={handleFileChange}
              extensions="video/mp4"
              error={errorData.file}
            />

            <p className="text-center py-2">
              <span className="fw-bold me-2">Peso actual:</span>
              {fileObj.peso || "0"} MB
            </p>

            {fileObj.record && (
              <h6 className="alert alert-warning mb-0 p-3 mb-3 text-center border rounded mt-3 mb-4">
                Actualmente se está utilizando el video grabado para su
                solicitud, puede cambiar de opción y subir un video clickeando
                en "Seleccionar archivo"
              </h6>
            )}

            {fileObj.upload && (
              <h6 className="alert alert-primary mb-0 p-3 mb-3 text-center border rounded my-4 mb-4">
                Se utilizará el archivo subido
              </h6>
            )}

            <div className="accordion mb-2" id="requisitos_input_file">
              <div className="accordion-item">
                <h2 className="accordion-header" id="titulo">
                  <button
                    className="accordion-button p-2 collapsed show"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#requisitos"
                    aria-expanded="false"
                    aria-controls="requisitos"
                  >
                    Requerimientos para subir archivo
                  </button>
                </h2>
                <div
                  id="requisitos"
                  className="accordion-collapse collapse show"
                  aria-labelledby="titulo"
                  data-bs-parent="#requisitos_input_file"
                  aria-expanded="false"
                >
                  <div className="accordion-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">Máximo 1 fichero</li>
                      <li className="list-group-item">Límite de 20 MB</li>
                      <li className="list-group-item">
                        Tipos permitidos:{" "}
                        <span className="text-danger">mp4</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
