import React from "react";

export default function StepsFeedback({ step }) {
  const BgOn = "bg-primary step-container rounded-circle text-white mb-2";
  const BgOFF = "bg-secondary step-container rounded-circle text-white mb-2";

  return (
    <div className="steps w-100 d-flex align-items-center justify-content-evenly mt-5">
      <div className="step-container d-flex flex-column w-auto h-auto align-items-center justify-content-center">
        <div className={step === 1 ? BgOn : BgOFF}>1</div>
        <p className="d-none d-md-block mb-0 fw-bold">Datos del solicitante</p>
      </div>

      <div className="step-container d-flex flex-column w-auto h-auto align-items-center justify-content-center">
        <div className={step === 2 ? BgOn : BgOFF}>2</div>
        <p className="d-none d-md-block mb-0 fw-bold">Datos del trámite</p>
      </div>

      <div className="step-container d-flex flex-column w-auto h-auto align-items-center justify-content-center">
        <div className={step === 3 ? BgOn : BgOFF}>3</div>
        <p className="d-none d-md-block mb-0 fw-bold">Firma</p>
      </div>

      <div className="step-container d-flex flex-column w-auto h-auto align-items-center justify-content-center">
        <div className={step === 4 ? BgOn : BgOFF}>4</div>
        <p className="d-none d-md-block mb-0 fw-bold">Comprobante</p>
      </div>
    </div>
  );
}
