import React from "react";

export default function Loading() {
  return (
    <div className="loading-cover d-flex align-items-center justify-content-center rounded h-100 w-100">
      <div
        className="spinner-border text-primary"
        style={{ width: "4rem", height: "4rem" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
