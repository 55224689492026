import React from "react";

export default function StepThreeInfoModal() {
  return (
    <div
      className="modal fade"
      id="stepThreeInfoModal"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="stepThreeInfoModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="stepThreeInfoModal">
              Tipo de solicitud
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <h4 className="text-center fs-4">Información</h4>

            <p className="text-center mb-5">
              Para que su trámite sea atendido de manera eficaz, usted tiene dos
              opciones para generar su solicitud:
            </p>

            <p className="text-start w-75 mx-auto">
              1. Solicitud de trámite (escaneado) lo puede realizar de dos
              maneras;
            </p>

            <ul className="list-group list-group-flush w-75 mx-auto mb-4">
              <li className="list-group-item">
                <span className="fw-bold me-2">Firma manual:</span>
                Descargar, imprimir, firmar manualmente y adjuntar la solicitud.
              </li>
              <li className="list-group-item">
                <span className="fw-bold me-2">Firma electrónica:</span>
                Descargar, firmar electrónicamente y adjuntar la solicitud.
              </li>
            </ul>

            <p className="text-start w-75 mx-auto">2. Video:</p>

            <ul className="list-group list-group-flush w-75 mx-auto">
              <li className="list-group-item">
                Realizar un video corto, leyendo el texto indicado en la
                plataforma
              </li>
            </ul>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
