import { POST } from "helpers/request";
import React, { useState } from "react";

import { useForm } from "hooks/useForm";
import { DefaultCheckbox, DefaultInput } from "templates/input";
import Loading from "../Loading";

const defaultValues = {
  ciu_varchar_code: "",
  ser_int_id: "",
  terminos: false,
};

export default function StepOneValidation({ setStep, verification }) {
  const [values, handleInputChange, reset] = useForm(defaultValues);
  const [errorData, setError] = useState(null);
  const [accepted, setAccepted] = useState(false);
  const [loading, setLoading] = useState(false);

  // maneja el submit del formulario, sus errores y cambia de paso si todo fue correcto
  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(null);
    setLoading(true);

    if (!values.terminos) {
      setLoading(false);
      return setAccepted("Debes aceptar los términos y condiciones");
    } else {
      setAccepted(null);
    }

    try {
      await POST(
        { ...values, ser_int_id: verification.ser_int_id },
        "ventanilla/steptwoform",
        setError,
        reset
      );

      setLoading(false);

      setStep(2);
      window.scroll({ top: 100, behavior: "smooth" });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const { ciu_varchar_code, terminos } = values;

  return (
    <form
      onSubmit={handleSubmit}
      className="w-100 p-1 p-md-3 p-xl-5 d-flex flex-column mt-3 position-relative border mb-5 rounded"
      autoComplete="off"
    >
      <h3 className="text-primary fw-normal mb-4">Valida tus datos</h3>

      {errorData && (
        <div className="alert alert-danger text-center fw-bold mb-0">
          {errorData.error}
        </div>
      )}

      {loading && <Loading />}

      <div className="list-group mb-2 mt-4 mb-3">
        <p className="list-group-item fs-5 bg-secondary text-white mb-0">
          Email
        </p>
        <div className="list-group-item">
          <p className="mt-1">
            Se ha enviado un código de confirmación a su correo electrónico:{" "}
            <span className="fw-bold">{verification.email}</span>
          </p>

          <DefaultInput
            colSize="col-12"
            label="Código de confirmación, enviado a su correo electrónico *"
            type="text"
            value={ciu_varchar_code}
            inputName="ciu_varchar_code"
            onChange={handleInputChange}
            placeholder="Ingrese el código de confirmación"
            min="3"
          />
        </div>
      </div>

      <div className="list-group mb-2">
        <p className="list-group-item fs-5 bg-secondary text-white mb-0">
          Política de términos de uso
        </p>
        <div className="list-group-item">
          <p className="mb-2">
            <span className="fw-bold">-</span> Confirmo que estoy autorizado a
            realizar este trámite y la información proporcionada es veraz y
            actualizada.
          </p>
          <p className="mb-2">
            <span className="fw-bold">-</span> Usted está de acuerdo con
            nuestros términos y condiciones, acepto que este sitio recoja
            información del usuario para poder brindar el servicio.
          </p>
          <p className="mb-2">
            <span className="fw-bold">-</span> Para conocer el tratamiento que
            damos a su información personal, revise la Política para el
            tratamiento de datos personales.
          </p>
        </div>
      </div>

      <DefaultCheckbox
        label="Acepto la política de términos de uso *"
        value={terminos}
        inputName="terminos"
        onChange={handleInputChange}
        error={accepted}
      />

      <div className="d-flex w-100 justify-content-end mt-4">
        <button
          type="button"
          className="btn btn-secondary mt-0"
          data-bs-toggle="modal"
          data-bs-target="#cancelarTramite"
        >
          Cancelar
        </button>
        <button type="submit" className="btn btn-success mt-0 ms-4">
          Siguiente
        </button>
      </div>
    </form>
  );
}
