import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { GET } from "helpers/request";

import Loading from "components/Loading";

import { DefaultInput } from "templates/input";
import getDateString from "helpers/dateString";

export default function TramiteInfo() {
  const submitBtn = useRef();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [code, setCode] = useState(id ?? "");
  const [data, setData] = useState(null);

  // busca la información del trámite según el código ingreaso por el usuario o si existe código en la url, esto si se ingresa por código QR a la web
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(false);

    try {
      const res = await GET("ventanilla/estado", code);
      setData(res.datos);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
    }
  };

  const handleChange = ({ target }) => {
    setData(null);
    setError(false);
    setCode(target.value);
  };

  // la primera vez que se monta el componente si existe id en la url reliza la búsqueda de información
  useEffect(() => {
    if (id) {
      return submitBtn.current.click();
    }

    setCode("");
  }, [id]);

  return (
    <section className="container py-5">
      <form
        onSubmit={handleSubmit}
        className="status-form d-flex align-items-end gap-3 mx-auto flex-wrap"
        autoComplete="off"
      >
        <DefaultInput
          customClass="flex-grow-1"
          mb="0"
          label="Ingrese el código de el trámite"
          placeholder="Ej: 20200101-10000"
          value={code}
          onChange={handleChange}
          min="14"
        />

        <button
          ref={submitBtn}
          type="submit"
          className="status-btn btn btn-primary"
        >
          Buscar
          <i className="fas fa-search ms-2"></i>
        </button>
      </form>

      {error && (
        <div className="status-form alert alert-danger text-center mt-5 mx-auto py-5">
          El trámite N° <span className="fw-bold">{code}</span> no existe,
          asegúrate de haberlo escrito correctamente. Ej: 20200101-10000
        </div>
      )}

      {!error && (
        <div className="status-card p-3 border rounded d-flex flex-column align-items-center justify-content-center mx-auto mt-5 position-relative">
          {loading && <Loading />}

          <div className="d-flex w-100 align-items-start gap-3 mb-2">
            <p className="mb-0 word-break fw-bold w-50">Fecha de consulta:</p>
            <p className="mb-0 word-break w-50">{getDateString(new Date())}</p>
          </div>

          <div className="d-flex w-100 align-items-start gap-3 mb-2">
            <p className="mb-0 word-break fw-bold w-50">Código:</p>
            <p className="mb-0 word-break w-50">{data !== null && code}</p>
          </div>

          <div className="d-flex w-100 align-items-start gap-3 mb-2">
            <p className="mb-0 word-break fw-bold w-50">Trámite:</p>
            <p className="mb-0 word-break w-50">{data?.tramite || ""}</p>
          </div>

          <div className="d-flex w-100 align-items-start gap-3">
            <p className="mb-0 word-break fw-bold w-50">Estado:</p>
            <p className="mb-0 word-break w-50">{data?.estado || ""}</p>
          </div>
        </div>
      )}
    </section>
  );
}
