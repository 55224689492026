import { POST_FILE } from "helpers/request";
import React, { useState } from "react";

import { useForm } from "hooks/useForm";
import { DefaultSelectNoPlaceholder, DefaultInput } from "templates/input";
import Loading from "../Loading";
import StepOneValidation from "./StepOneValidation";
import ThirdPartyForm from "components/step-one-components/ThirdPartyForm";
import Tramite3AlertPrev from "components/process-components/Tramite-3-alert-prev";

const defaultValues = {
  tip_int_id: "",
  //email
  ciu_varchar_user: "",
  ciu_varchar_name: "",
  ciu_varchar_lastname: "",
  //tipo de identificación, 1 cédula, 2 pasaporte, 3 ruc
  ciu_varchar_dni_tipo: "",
  //valor de ese dni
  ciu_varchar_dni: "",
  ciu_varchar_phone: "",

  // ----------------------------------------

  // 1 es para terceros 2 es personal
  is_third_party: "2",

  // ----------------------------------------

  // variables si es para terceros
  pon_varchar_name: "",
  pon_varchar_lastname: "",
  // 1 cédula 2 pasaporte
  pon_varchar_dni_tipo: "",
  pon_varchar_dni: "",
  pon_varchar_correo: "",
  pon_varchar_celular: "",
  file: "",
};

// prettier-ignore
export default function StepOneForm({ setStep, thirdParty, setProcessId, setUserData, tramiteId }) {
  // custom hook que guarda la info del formulario y devuelve las funciones y estado para verlo o cambiar sus valores
  const [values, handleInputChange, reset] = useForm({
    ...defaultValues,
    ciu_varchar_dni_tipo: "1",
    is_third_party: thirdParty ? "1" : "2",
    pon_varchar_dni_tipo: '1'
  });

  // guarda el archivo subido por el usuario
  const [file, setFile] = useState(null);

  const [errorData, setError] = useState(defaultValues);
  const [loading, setLoading] = useState(false);

  // estado que maneja si se mostrará o no el componente para verificación
  const [verification, setVerification] = useState({
    verify: false,
  });

  // maneja el submit del formulario y sus errores, si es exitoso cambia de paso
  const handleSubmit = async (e) => {
    e.preventDefault();

    // si es un trámite para terceros requiere archivo 
    if(values.is_third_party === '1' && !file) {
      return setError({...errorData, file: 'Debe adjuntar este archivo'})
    }

    setLoading(true);

    try {
      const resData = await POST_FILE(
        { ...values, tip_int_id: tramiteId, file},
        "ventanilla/steponeform",
        setError,
        reset
      );

      setLoading(false);

      // process tramite id for steps request handle
      setProcessId(resData.data.ser_int_id);

      // verification data for step one validation form 
      setVerification({
        verify: true,
        fullname: values.ciu_varchar_name + " " + values.ciu_varchar_lastname,
        email: values.ciu_varchar_user,
        ...resData.data,
      });

      // info for process component user data
      setUserData({
        fullname: values.ciu_varchar_name + " " + values.ciu_varchar_lastname,
        email: values.ciu_varchar_user,
        cdla: values.ciu_varchar_dni,
        code: resData.data.ser_text_codigo,
        idType:
          (values.ciu_varchar_dni_tipo === "1" && "Cédula") ||
          (values.ciu_varchar_dni_tipo === "2" && "Pasaporte") ||
          (values.ciu_varchar_dni_tipo === "3" && "RUC"),
        phone: values.ciu_varchar_phone,
      });

      window.scroll({ top: 100, behavior: "smooth" });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const {
    ciu_varchar_name,
    ciu_varchar_lastname,
    ciu_varchar_user,
    ciu_varchar_dni_tipo,
    ciu_varchar_dni,
    ciu_varchar_phone,
  } = values;

  return (
    <>
      {!verification.verify ? (
        <form
          onSubmit={handleSubmit}
          className="w-100 p-1 p-md-3 p-xl-5 d-flex flex-column mt-3 position-relative border mb-5 rounded"
          autoComplete="off"
        >
          {loading && <Loading />}

          <div className="list-group mb-3">
            <p className="list-group-item fs-5 bg-secondary text-white mb-0">
              {thirdParty ? 'Información del apoderado (Sus datos personales):' : "Información del solicitante (Sus datos personales):"}
            </p>

            <div className="list-group-item px-1 px-lg-3">
              <div className="row w-100 mx-0">
                <DefaultInput
                  customClass="col-12 col-md-6"
                  label="Nombres"
                  type="text"
                  value={ciu_varchar_name}
                  inputName="ciu_varchar_name"
                  onChange={handleInputChange}
                  placeholder="Ingrese sus nombres"
                  error={errorData.ciu_varchar_name}
                  min="3"
                />

                <DefaultInput
                  customClass="col-12 col-md-6"
                  label="Apellidos"
                  type="text"
                  value={ciu_varchar_lastname}
                  inputName="ciu_varchar_lastname"
                  onChange={handleInputChange}
                  placeholder="Ingrese sus apellidos"
                  error={errorData.ciu_varchar_lastname}
                  min="3"
                />
              </div>

              <div className="row w-100 mx-0">
                <DefaultSelectNoPlaceholder
                  customClass="col-12 col-md-6"
                  label="Tipo de documento de identificación"
                  type="text"
                  value={ciu_varchar_dni_tipo}
                  inputName="ciu_varchar_dni_tipo"
                  onChange={handleInputChange}
                  error={errorData.ciu_varchar_dni_tipo}
                  options={[
                    { value: "1", name: "Cédula" },
                    { value: "2", name: "Pasaporte" },
                    {
                      value: "3",
                      name: "Registro Único de Contribuyente (RUC)",
                    },
                  ]}
                />

                <DefaultInput
                  customClass="col-12 col-md-6"
                  label={
                    (ciu_varchar_dni_tipo === "1" && "Cédula") ||
                    (ciu_varchar_dni_tipo === "2" && "Pasaporte") ||
                    (ciu_varchar_dni_tipo === "3" &&
                      "Registro Único de Contribuyente (RUC)")
                  }
                  type={ciu_varchar_dni_tipo === "2" ? 'text' : 'number'}
                  value={ciu_varchar_dni}
                  inputName="ciu_varchar_dni"
                  onChange={handleInputChange}
                  placeholder="Ingrese su identificación"
                  error={errorData.ciu_varchar_dni}
                  min="10"
                />
              </div>

              <div className="row w-100 align-items-end mx-0">
                <DefaultInput
                  customClass="col-12 col-md-6"
                  label="Correo electrónico (Se enviará código de confirmación)"
                  type="email"
                  value={ciu_varchar_user}
                  inputName="ciu_varchar_user"
                  onChange={handleInputChange}
                  placeholder="Ingrese su correo"
                  error={errorData.ciu_varchar_user}
                  min="3"
                />

                <DefaultInput
                  customClass="col-12 col-md-6"
                  label="Teléfono"
                  type="number"
                  value={ciu_varchar_phone}
                  inputName="ciu_varchar_phone"
                  onChange={handleInputChange}
                  placeholder="Ingrese su teléfono"
                  error={errorData.ciu_varchar_phone}
                  required={false}
                  min="6"
                />
              </div>

              <span className="fw-normal rounded py-1 rounded mb-2">
                <i className="fas fa-exclamation-circle me-2 text-warning"></i>
                Recuerde que al completar todos los datos se podrá realizar la atención de manera más ágil
              </span>
            </div>
          </div>

          {tramiteId === '3' && <Tramite3AlertPrev />}
          
          {/* Formulario de datos necesario de ser un trámite para terceros */}
          {thirdParty && <ThirdPartyForm values={values} handleInputChange={handleInputChange} errorData={errorData} setFile={setFile} setError={setError}/>}

          <div className="d-flex w-100 justify-content-end mt-4">
            <button
              type="button"
              className="btn btn-secondary mt-0"
              data-bs-toggle="modal"
              data-bs-target="#cancelarTramite"
            >
              Cancelar
            </button>
            <button type="submit" className="btn btn-success mt-0 ms-4">
              Siguiente
            </button>
          </div>
        </form>
      ) : (
        // Componente de validación de código por correo para el trámite
        <StepOneValidation setStep={setStep} verification={verification} setError={setError} errorData={errorData} />
      )}
    </>
  );
}
