import React from "react";
import { DefaultInput, DefaultSelect, DefaultTextarea } from "templates/input";

export default function Tramite3StepTwoForm({
  handleInputChange,
  errorData,
  ciu_varchar_address,
  ciu_varchar_estadocivil,
  ciu_varchar_phonefijo,
  ciu_varchar_fechaingreso,
  ciu_varchar_unidadcontrol,
  ciu_varchar_motivoestadia,
}) {
  return (
    <div className="w-100 d-flex flex-column">
      <DefaultInput
        label="Dirección domiciliaria en Ecuador"
        type="text"
        value={ciu_varchar_address}
        inputName="ciu_varchar_address"
        onChange={handleInputChange}
        placeholder="Ingrese su dirección"
        error={errorData.ciu_varchar_address}
        min="3"
      />

      <DefaultSelect
        label="Estado civíl"
        value={ciu_varchar_estadocivil}
        inputName="ciu_varchar_estadocivil"
        onChange={handleInputChange}
        defaultValue={ciu_varchar_estadocivil}
        error={errorData.ciu_varchar_estadocivil}
        options={[
          { value: "1", name: "Soltero" },
          { value: "2", name: "Casado" },
          { value: "3", name: "Divorciado" },
        ]}
      />

      <DefaultInput
        label="Teléfono de contacto"
        type="text"
        value={ciu_varchar_phonefijo}
        inputName="ciu_varchar_phonefijo"
        onChange={handleInputChange}
        placeholder="Ingrese su dirección"
        error={errorData.ciu_varchar_phonefijo}
        min="3"
      />

      <DefaultInput
        label="Fecha de ingreso a Ecuador"
        type="date"
        value={ciu_varchar_fechaingreso}
        inputName="ciu_varchar_fechaingreso"
        onChange={handleInputChange}
        placeholder="Ingrese su dirección"
        error={errorData.ciu_varchar_fechaingreso}
        min="3"
      />

      <DefaultInput
        label="Unidad de Control Migratorio de su ingreso al País"
        type="text"
        value={ciu_varchar_unidadcontrol}
        inputName="ciu_varchar_unidadcontrol"
        onChange={handleInputChange}
        placeholder="Ingrese su dirección"
        error={errorData.ciu_varchar_unidadcontrol}
        min="3"
      />

      <DefaultTextarea
        label="Motivo de su estadía en Ecuador"
        value={ciu_varchar_motivoestadia}
        inputName="ciu_varchar_motivoestadia"
        onChange={handleInputChange}
        placeholder="Ingrese su dirección"
        error={errorData.ciu_varchar_motivoestadia}
        min="3"
        rows="3"
      />
    </div>
  );
}
