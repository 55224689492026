import React from "react";

import { DefaultFile } from "templates/input";

export default function FirmaManual({
  handleFileChange,
  errorData,
  handleForceDownload,
  fileObj,
}) {
  return (
    <>
      <div className="list-group mb-5">
        <p className="list-group-item fs-5 bg-secondary text-white mb-0">
          Descargue la solicitud generada
        </p>

        <div className="list-group-item px-1 px-lg-3 mt-2 d-flex flex-column align-items-center">
          <p className="text-center">
            <i className="fas fa-exclamation-circle me-2 text-warning"></i>
            Descargar, imprimir, firmar y adjuntar a continuación (Puede ser el
            documento escaneado o una foto clara)
          </p>
          <button
            onClick={handleForceDownload}
            type="button"
            className="btn btn-primary d-block mb-3 px-5"
          >
            Descargar
            <i className="fas fa-download ms-2"></i>
          </button>
        </div>
      </div>

      <div className="list-group mb-3">
        <p className="list-group-item fs-5 bg-secondary text-white mb-0">
          Adjunte el documento firmado escaneado
        </p>

        <div className="list-group-item px-1 px-lg-3 mt-2">
          <DefaultFile
            customClass="col-12"
            label="Escoja su documento"
            inputName="file"
            extensions="application/pdf, image/*"
            onChange={handleFileChange}
            error={errorData.file}
          />

          <p className="text-center py-2">
            <span className="fw-bold me-2">Peso actual:</span>
            {fileObj.peso || "0"} MB
          </p>

          <div className="accordion mb-2" id="requisitos_input_file">
            <div className="accordion-item">
              <h2 className="accordion-header" id="titulo">
                <button
                  className="accordion-button p-2 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#requisitos"
                  aria-expanded="false"
                  aria-controls="requisitos"
                >
                  Requerimientos para subir archivo
                </button>
              </h2>
              <div
                id="requisitos"
                className="accordion-collapse collapse show"
                aria-labelledby="titulo"
                data-bs-parent="#requisitos_input_file"
                aria-expanded="false"
              >
                <div className="accordion-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">Máximo 1 fichero</li>
                    <li className="list-group-item">Límite de 2 MB</li>
                    <li className="list-group-item">
                      Tipos permitidos:{" "}
                      <span className="text-danger">PDF, JPG, PNG, JPEG</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
