export default function InputFile({
  className,
  label,
  id,
  isInvalid,
  onChange,
  name,
  extensions,
  required = true,
  multiple = false,
}) {
  return (
    <div className={className}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <input
        className={`form-control ${isInvalid ? "is-invalid" : ""}`}
        type="file"
        id={id}
        required={required}
        onChange={onChange}
        name={name}
        accept={extensions}
        multiple={multiple}
      />
      {isInvalid && (
        <div id={`${id}Feedback`} className="invalid-feedback">
          {isInvalid}
        </div>
      )}
    </div>
  );
}
