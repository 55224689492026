import axios from "axios";

// url con path admin
const BASE_URL = process.env.REACT_APP_BASE_BASE_URL;
// url base sin path adicional
const BASE_BASE_URL = process.env.REACT_APP_BASE_BASE_URL;

// tokens auth
const CLIENT = process.env.REACT_APP_OAUTH2_CLIENT;
const SECRET = process.env.REACT_APP_OAUTH2_SECRET;

// configuración para request de tipo formData
export const configMultipart = () => {
  return {
    headers: {
      Authorization: `Basic ${btoa(CLIENT + ":" + SECRET)}`,
      "Content-Type": "multipart/form-data",
    },
  };
};

// configuración básica para request de login
const config = (responseType) => {
  return {
    responseType: responseType,
    headers: {
      Authorization: `Basic ${btoa(CLIENT + ":" + SECRET)}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
};

// request tipo post, mapea los valores recibidos y los pasa a tipo URLSearchParams
// recibe la funcion setError para guardar errores. es opcional
export async function POST(values, module, setError) {
  const URL = BASE_URL + module;

  // para enviar la data en formato x-www-form-urlencoded
  const params = new URLSearchParams();
  for (const key in values) {
    params.append(key, values[key]);
  }

  try {
    const { data } = await axios.post(URL, params, config());
    return data;
  } catch (error) {
    setError?.(error.response?.data.messages);
    throw error;
    // return error.response?.data.messages;
  }
}

// request tipo post para archivos, usa el formato formData y la función configMultipart
// recibe la funcion setError para guardar errores. es opcional
export async function POST_FILE(values, module, setError) {
  const URL = BASE_URL + module;

  // para enviar la data en formato x-www-form-urlencoded
  const formData = new FormData();
  for (const key in values) {
    if (values[key] !== undefined) {
      formData.append(key, values[key]);
    }
  }

  try {
    const { data } = await axios.post(URL, formData, configMultipart());
    return data;
  } catch (error) {
    setError?.(error.response?.data.messages);
    throw error;
    // return error.response?.data.messages;
  }
}

export async function GET(module, id = null) {
  let URL;

  if (id) {
    URL = BASE_URL + module + "/" + id;
  } else {
    URL = BASE_URL + module;
  }

  try {
    const { data } = await axios.get(URL, config());
    return data;
  } catch (error) {
    console.log(error);
  }
}

// request get a la URL raíz
export async function GET_BASE(module, id = null) {
  let URL;

  if (id) {
    URL = BASE_BASE_URL + module + "/" + id;
  } else {
    URL = BASE_BASE_URL + module;
  }

  try {
    const { data } = await axios.get(URL, config());
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function DELETE(module, id) {
  if (!id) {
    throw new Error("Necesitas enviar un id");
  }

  const URL = BASE_URL + module + "/" + id;

  try {
    const data = await axios.delete(URL, config());
    return data;
  } catch (error) {
    console.log(error);
  }
}

// request tipo post, recibe valores por defecto y valores con información, en base a los valores por defecto mapea los valores con datos
// y no agrega a la petición aquellos campos que no concuerden con los valores por defecto o que estén vacíos
// recibe la funcion setError y reset, para guardar errores o limpiar el formulario, las dos son opcionales
export async function PUT(defaultValues, values, module, id, setError, reset) {
  const URL = BASE_URL + module + "/" + id;

  let editContent = {};

  for (const key in defaultValues) {
    editContent = { ...editContent, [key]: values[key] };
  }

  // para enviar la data en formato x-www-form-urlencoded
  const params = new URLSearchParams();
  for (const key in editContent) {
    params.append(key, values[key]);
  }

  try {
    const { data } = await axios.put(URL, params, config());
    reset?.();
    return data;
  } catch (error) {
    console.log(error.response.data.messages);
    return setError?.(error.response?.data.messages);
  }
}

// request para descargar archivos, debe recibir el módulo de la url y nombre del archivo
// primero hace un get del archivo, si este existe creará un elementio tipo <a></a> en el html
// y le dara la prop donwload con el nombre del archivo para decargar
export async function PDF_DWNLD_GET(module, name = "file", id = null) {
  let URL;
  if (id) {
    URL = BASE_URL + module + "/" + id;
  } else {
    URL = BASE_URL + module;
  }

  try {
    const { data } = await axios.get(URL, config("blob"));
    const href = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", name); //or any other extension
    document.body.appendChild(link);
    link.click();
    return data;
  } catch (error) {
    console.log(error);
  }
}
