import React from "react";

export default function Tramite3AlertPrev() {
  return (
    <div className="alert alert-success" role="alert">
      <h5 className="mb-3 fw-bold text-start">
        Información importante antes de solicitar una prórroga
      </h5>

      <p className="mb-3 text-justify">
        El plazo de permanencia para los ciudadanos en calidad de turistas será
        de hasta noventa días en el periodo de un año contado a partir de su
        primer ingreso, prorrogable por una sola vez hasta por noventa días
        adicionales, previa solicitud y pago de la tarifa respectiva,
        correspondiente a la tercera parte de un salario básico unificado.
      </p>

      <p className="mb-0 text-justify">
        NOTA: No aplica para ciudadanos suramericanos y ciudadanos cuyas
        nacionalidades requieran visa obligatoria para ingreso a Ecuador.
      </p>
    </div>
  );
}
