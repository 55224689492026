import React from "react";

import oim from "../imgs/OIM-PRM.svg";

import "styles/footer.css";

export default function Footer() {
  const handleClick = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  return (
    <footer
      className="text-white container-fluid mx-0 px-5 py-5"
      style={{ backgroundColor: "#141414" }}
    >
      <div className="container mx-auto">
        <div className="row g-4 gy-5 row-cols-auto justify-content-center mx-0 px-0">
          <div
            className="col d-flex flex-column gap-4 align-items-center justify-content-center"
            style={{ width: "400px" }}
          >
            <img src={oim} id="footer-gob-img" alt="Ministerio de gobierno" />
          </div>

          <div className="col" style={{ width: "400px" }}>
            <h4 className="fw-bold text-start mb-5">Comunícate con nosotros</h4>
            <p className="mb-0">
              Acuda directamente a las oficinas de Servicio de Apoyo Migratorio
              ubicadas a escala nacional.
            </p>
          </div>

          <div className="col" style={{ width: "400px" }}>
            <p className="text-break">
              <span className="fw-bold text-uppercase">CORREO:</span>
              <br />
              servicios.migratorios@ministeriodegobierno.gob.ec
            </p>
            <p className="text-break">
              <span className="fw-bold text-uppercase">DIRECCIÓN:</span>
              <br />
              Quito Av. Amazonas N32-171 y Av. República TELEFONO: (02) 2276394
            </p>
          </div>

          <div className="d-flex align-items-center justify-content-center w-100">
            <div
              style={{ backgroundColor: "yellow", width: "50%", height: "5px" }}
            ></div>
            <div
              style={{ backgroundColor: "blue", width: "25%", height: "5px" }}
            ></div>
            <div
              style={{ backgroundColor: "red", width: "25%", height: "5px" }}
            ></div>
          </div>

          <div className="mt-5 w-100 d-flex justify-content-center justify-content-sm-center align-items-center flex-wrap">
            <p className="w-auto text-center">
              Todos los derechos reservados www.ministeriodegobierno.gob.ec
            </p>
            <button
              onClick={handleClick}
              type="button"
              className="btn text-white p-1 ms-0 ms-sm-auto"
            >
              <i className="fas fa-chevron-up fa-2x"></i>
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
}
