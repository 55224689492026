import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { PDF_DWNLD_GET } from "helpers/request";
import ProcessData from "data/ProcessData";

export default function StepFourForm({
  processId,
  userData,
  fileName,
  tramiteId,
}) {
  const location = useLocation();

  // obtiene el nombre del trámite por el id de la url y la info del local storage
  const getServiceName = () => {
    const pathId = location.pathname.split("/")[2];
    const hashTable = window.localStorage.getItem("tramites");
    const tableParse = JSON.parse(hashTable);

    return tableParse[pathId];
  };

  // descarga la solicitud del trámite
  const handleDownloadRequest = async () => {
    const url =
      tramiteId !== "6"
        ? `ventanilla/stepfourform/${processId}?t=s`
        : `ventanilla/recepcion/${processId}?que=f`;

    await PDF_DWNLD_GET(
      url,
      `Solicitud-${userData.code}.${fileName.split(".")[1]}`
    );
  };

  // descarga el comprobante del trámite
  const handleDownloadProof = async () => {
    await PDF_DWNLD_GET(
      `ventanilla/stepfourform/${processId}?t=c`,
      `Comprobante-${userData.code}.pdf`
    );
  };

  // si el trámite es de ID 7, muestra un componente especial ya que este no necesita comprobantes etc...
  if (tramiteId === "7" || tramiteId === "8") {
    return <CustomComponent />;
  }

  return (
    <div className="w-100 p-1 p-md-3 p-xl-5 d-flex flex-column mt-3 position-relative border mb-5 rounded">
      <div className="list-group mb-2">
        <p className="list-group-item fs-5 bg-secondary text-white mb-0">
          Comprobante de inicio de trámite
        </p>
        <div className="list-group-item">
          <div className="row mx-0">
            <div className="col-12 col-lg-7 mb-3 mt-2">
              <h5 className="text-primary">
                <i className="far fa-list-alt me-2"></i>
                Próximos pasos
              </h5>

              <ul className="w-100">
                {ProcessData[tramiteId].next_steps.map((item, index) => (
                  <li key={index} className="mb-1">
                    {item}
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-12 col-lg-5 mb-3 mt-2">
              <h5 className="text-primary">
                <i className="far fa-envelope me-2"></i>
                Contacto para atención ciudadana
              </h5>

              <p className="mb-2">
                <span className="fw-bold">Contacto:</span> DIRECCIÓN DE
                SERVICIOS MIGRATORIOS
              </p>
              <p className="mb-2 text-break">
                <span className="fw-bold">Email:</span>{" "}
                servicios.migratorios@ministeriodegobierno.gob.ec
              </p>
              <p className="mb-2">
                <span className="fw-bold">Teléfono:</span> 022 - 276 - 394
              </p>
            </div>

            <hr />

            <div className="col-12 col-lg-7 mb-3 mt-2">
              <h5 className="text-primary">
                <i className="far fa-sticky-note me-2"></i>
                Información de solicitud
              </h5>

              <p className="mb-2">
                <span className="fw-bold">Trámite:</span> {getServiceName()}
              </p>
              <p className="mb-2">
                <span className="fw-bold">Número de solicitud:</span>{" "}
                {userData.code}
              </p>
              <p className="mb-2">
                <span className="fw-bold">Atención:</span> Ministerio de
                Gobierno (MDG)
              </p>
              <p className="mb-2">
                <span className="fw-bold">Tiempo estimado de atención:</span>
                {ProcessData[tramiteId].estimatedTime ?? "2 Día(s) Laborables"}
              </p>
            </div>

            <div className="col-12 col-lg-5 mb-3 mt-2">
              <h5 className="text-primary">
                <i className="far fa-id-card me-2"></i>
                Solicitante
              </h5>

              {userData.cdla && (
                <p className="mb-2">
                  <span className="fw-bold">Identificación:</span>{" "}
                  {userData.cdla}
                </p>
              )}
              <p className="mb-2">
                <span className="fw-bold">Nombre:</span> {userData.fullname}
              </p>
              <p className="mb-2">
                <span className="fw-bold">Email:</span> {userData.email}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-lg-row w-100 justify-content-end mt-4">
        <button
          type="button"
          className="btn btn-info text-white mt-0 mb-2 mb-lg-0"
          onClick={handleDownloadRequest}
        >
          <i className="fas fa-download me-2"></i>
          Descargar solicitud
        </button>

        <button
          onClick={handleDownloadProof}
          type="button"
          className="btn btn-success mt-0 mb-2 mb-lg-0 mx-lg-3"
        >
          <i className="fas fa-download me-2"></i>
          Descargar comprobante
        </button>

        <Link to="/" className="btn btn-secondary mt-0">
          <i className="fas fa-home me-2"></i>
          Página principal
        </Link>
      </div>
    </div>
  );
}

const CustomComponent = () => {
  return (
    <div className="w-100 p-1 p-md-3 p-xl-5 d-flex flex-column mt-3 position-relative border mb-5 rounded">
      <div className="list-group mb-2">
        <p className="list-group-item fs-5 bg-secondary text-white mb-0">
          Trámite completado con éxito
        </p>
        <div className="list-group-item py-5">
          <h3 className="text-center">¡Gracias por tu información!</h3>
          <p className="text-center mb-0">
            Nuestros analistas estarán pronto en contacto 🧑‍💻
          </p>
        </div>
      </div>

      <div className="d-flex flex-column flex-lg-row w-100 justify-content-end mt-4">
        <Link to="/" className="btn btn-secondary mt-0">
          <i className="fas fa-home me-2"></i>
          Página principal
        </Link>
      </div>
    </div>
  );
};
